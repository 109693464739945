import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Box,
  useMediaQuery,
  useTheme,
  Container,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useOrganisation } from "../../../../hooks/useOrganisation";

const ArticleSection = ({ articles }) => {
  const { primaryColor } = useOrganisation();
  const navigate = useNavigate();
  const theme = useTheme();
  const companyName = localStorage.getItem("companyName");

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const firstArticle = articles?.[0];
  const secondList = articles?.slice(1, 5);
  const thirdList = articles?.slice(5);

  return (
    <Container maxWidth="lg" className="sectionCenter">
      <Grid container spacing={3} sm={12} xs={12} md={12}>
        {/* First Column */}
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {firstArticle && (
            <Card
              style={{
                flex: 1,
                cursor: "pointer",
                boxShadow: "none",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                borderRadius: 0,
              }}
              onClick={() =>
                navigate(`/${companyName}/article/details/${firstArticle.id}`)
              }
            >
              <CardMedia
                component="img"
                height="250"
                image={`${
                  firstArticle?.imageLink
                }?${"sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D"}`}
                alt="Article Image"
              />
              <CardContent
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                <Typography
                  variant="overline"
                  color="textSecondary"
                  style={{
                    color: primaryColor,
                    fontWeight: "bold",
                    fontSize: 10,
                  }}
                >
                  {firstArticle?.topics.split(",").slice(0, 3).join(", ")}
                </Typography>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    fontWeight: "bold",
                    fontSize: 24,
                  }}
                >
                  {firstArticle?.title}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    overflow: "hidden",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: firstArticle?.text }}
                  />
                </Typography>
                <Box style={{ marginTop: "auto" }}>
                  <Typography variant="caption" color="textSecondary">
                    {firstArticle?.scheduledDate &&
                      formatDistanceToNow(
                        new Date(firstArticle?.scheduledDate),
                        {
                          addSuffix: true,
                        }
                      )}
                  </Typography>
                  <hr
                    style={{
                      border: 0,
                      borderTop: "1px solid #ccc",
                      margin: "8px 0",
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          )}
        </Grid>

        {/* Second Column */}
        <Grid item xs={12} sm={6} md={4} container spacing={1}>
          {secondList?.map((el, index) => (
            <Grid item xs={6} key={index} style={{ display: "flex" }}>
              <Card
                style={{
                  cursor: "pointer",
                  boxShadow: "none",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 0,
                }}
                onClick={() =>
                  navigate(`/${companyName}/article/details/${el.id}`)
                }
              >
                <CardMedia
                  component="img"
                  height="100"
                  width={"100%"}
                  image={`${
                    el?.imageLink
                  }?${"sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D"}`}
                  alt="Article Image"
                />
                <CardContent
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    height: "100px",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      marginBottom: "auto",
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                  >
                    {el.title}
                  </Typography>
                  <Box>
                    <Typography variant="caption" color="textSecondary">
                      {el?.scheduledDate &&
                        formatDistanceToNow(new Date(el?.scheduledDate), {
                          addSuffix: true,
                        })}
                    </Typography>
                    <hr
                      style={{
                        border: 0,
                        borderTop: "1px solid #ccc",
                        margin: "8px 0",
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Third Column */}
        <Grid
          item
          xs={12}
          sm={12}
          md={isMediumScreen ? 12 : 2}
          container
          spacing={2}
          style={
            {
              // maxWidth: "300px",
            }
          }
        >
          {thirdList?.map((el, index) => (
            <Grid
              item
              xs={isMediumScreen ? 6 : 12}
              key={index}
              style={{ display: "flex" }}
              width={"100%"}
            >
              <Card
                style={{
                  cursor: "pointer",
                  boxShadow: "none",
                  display: "flex",
                  flexDirection: "column",
                  background: "#8080807a",
                  borderRadius: 0,
                  width: "100%",
                }}
                onClick={() =>
                  navigate(`/${companyName}/article/details/${el.id}`)
                }
              >
                <CardContent
                  style={{ flex: 1, display: "flex", flexDirection: "column" }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      marginBottom: "auto",
                      fontWeight: "bold",
                      fontSize: "10px",
                    }}
                    gutterBottom
                  >
                    <b>{el.title}</b>
                  </Typography>
                  <Box sx={{ fontWeight: "bold" }}>
                    <Typography variant="caption" color="textSecondary">
                      {el?.scheduledDate &&
                        formatDistanceToNow(new Date(el?.scheduledDate), {
                          addSuffix: true,
                        })}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ArticleSection;
