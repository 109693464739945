import React, { useEffect } from "react";
import AllContentPage from "../../../compnents/pages/AllContent";
import { Typography } from "@mui/material";

const AllApprovedContent = () => {
  useEffect(() => {
    document.title = `Approved Content`;
    window.scrollTo(0, 0);
  }, []);
  return (
    <main id="main" tabIndex={0}>
      <section className="sectionCenter">
        <Typography
          variant="h1"
          fontSize={24}
          color={"gray"}
          fontWeight={"bold"}
        >
          Dashboard
        </Typography>
        <hr />
        <AllContentPage approved={true} />
      </section>
    </main>
  );
};

export default AllApprovedContent;
