import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Grid, Paper, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { getAllNotificationStatistics } from "../../../../store/api/statistics";
import DataCard from "../DataCard";
import { useOrganisation } from "../../../../hooks/useOrganisation";

const NotificationTab = ({
  tabIndex,
  index,
  startDate,
  endDate,
  organisation,
}) => {
  const [selectedBar, setSelectedBar] = React.useState(null);
  const { primaryColor } = useOrganisation();
  // Query for overall user statistics
  const { data: statistics } = useQuery({
    queryKey: ["userStatistics", startDate, endDate],
    queryFn: () =>
      getAllNotificationStatistics({
        startDate,
        endDate,
        organisationId: organisation,
      }),
  });

  const handleBarClick = (elements) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      setSelectedBar(index);
    }
  };
  //   getAllNotificationStatistics;

  const chartOptions = {
    onClick: (e, elements) => handleBarClick(elements),
    scales: {
      y: { beginAtZero: true },
      x: {
        categoryPercentage: 0.6, // Adjust this to reduce space taken by each category
        barPercentage: 0.5, // Adjust this to reduce the bar width
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  };

  // Sample data for the Notification Events chart
  const data = {
    labels: ["Email", "Push", "SMS"],
    datasets: [
      {
        label: "Notification Events",
        data: [
          statistics?.emails || 0,
          statistics?.pushs || 0,
          statistics?.sms || 0,
        ], // Sample data
        backgroundColor: primaryColor, // Match the chart bar color with screenshot (light green)
        borderWidth: 1,
      },
    ],
  };
  return (
    <div>
      {/* Chart and Stats Cards */}
      <Grid
        container
        spacing={4}
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={6}>
          {/* Notification Events Chart */}
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h6">Notification Events</Typography>
            <Bar data={data} options={chartOptions} />
          </Paper>
        </Grid>

        {/* Statistics Cards */}
        <Grid item xs={12} md={4}>
          <Grid container spacing={2} alignItems="stretch">
            {[
              {
                title: "Email Click Rate",
                count: `${statistics?.emailClickRate.toFixed(2)}%` || 0,
              },

              {
                title: "Push Click Rate",
                count: statistics?.pushClickRate.toFixed(2) + "%" || 0,
              },
              {
                title: "SMS Click Rate",
                count: statistics?.smsClickRate.toFixed(2) + "%" || 0,
              },
            ].map((el, i) => (
              <Grid item xs={6} sm={3} md={6} key={i}>
                <DataCard key={i} title={el.title} count={el.count} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotificationTab;
