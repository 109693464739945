import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import {
  getUserPrefrences,
  updateUserPrefrences,
} from "../../../../store/api/users";

import Toast from "../../../common/Toast";
import Button from "../../../common/Button";
import { useOrganisation } from "../../../../hooks/useOrganisation";

const Preferences = () => {
  const queryClient = useQueryClient();
  const { primaryColor } = useOrganisation();

  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [defaultView, setDefaultView] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [status, setStatus] = useState(200);
  const [toastKey, setToastKey] = useState(0);

  const { data, isLoading } = useQuery({
    queryKey: ["userPrefrences"],
    queryFn: () => getUserPrefrences(),
  });
  const showToast = (message: string, statusCode: number) => {
    setResponseMessage(message);
    setStatus(statusCode);
    setToastKey((prev) => prev + 1); // Increment key to force re-render
  };

  const validateSelections = () => {
    if (!defaultView) return true;

    if (selectedTopics.length === 0) {
      showToast("Please select at least one topic for default view", 400);
      return false;
    }

    if (selectedTypes.length === 0) {
      showToast("Please select at least one type for default view", 400);
      return false;
    }

    if (selectedRegions.length === 0) {
      showToast("Please select at least one region for default view", 400);
      return false;
    }

    return true;
  };
  const { mutate, isPending } = useMutation({
    mutationFn: updateUserPrefrences,
    onSuccess: async (data) => {
      queryClient.refetchQueries({
        queryKey: ["userPrefrences"],
      });
      showToast("Preference Updated", 200);
    },
    onError: (error: any) => {
      console.log(error);
      showToast("Failed to update preferences", 400);
    },
  });

  useEffect(() => {
    if (!isLoading && data) {
      const initialSelectedTopics = data?.topics
        .filter((topic) => topic.isSelected)
        .map((topic) => topic.id);
      const initialSelectedTypes = data?.types
        .filter((region) => region.isSelected)
        .map((region) => region.id);
      const initialSelectedRegions = data?.regions
        .filter((region) => region.isSelected)
        .map((region) => region.id);

      setSelectedTopics(initialSelectedTopics || []);
      setSelectedTypes(initialSelectedTypes || []);
      setSelectedRegions(initialSelectedRegions || []);
      setDefaultView(data?.isDefaultView);
    }
  }, [isLoading, data]);

  // Handler for types
  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedTypes((prev) => [...prev, value]);
    } else {
      setSelectedTypes((prev) => prev.filter((region) => region !== value));
    }
  };

  // Handler for regions
  const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedRegions((prev) => [...prev, value]);
    } else {
      setSelectedRegions((prev) => prev.filter((region) => region !== value));
    }
  };

  // Handler for topics
  const handleTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedTopics((prev) => [...prev, value]);
    } else {
      setSelectedTopics((prev) => prev.filter((topic) => topic !== value));
    }
  };

  const handleSelectAllTypes = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && data?.types) {
      const allTypesIds = data.types.map((region) => region.id);
      setSelectedTypes(allTypesIds);
    } else {
      setSelectedTypes([]);
    }
  };

  const handleSelectAllTopics = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked && data?.topics) {
      const allTopicIds = data.topics.map((topic) => topic.id);
      setSelectedTopics(allTopicIds);
    } else {
      setSelectedTopics([]);
    }
  };

  const handleSelectAllRegions = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked && data?.regions) {
      const allRegionIds = data.regions.map((region) => region.id);
      setSelectedRegions(allRegionIds);
    } else {
      setSelectedRegions([]);
    }
  };

  const handleDefaultViewChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setDefaultView(checked);

    // If enabling default view, validate current selections
    if (checked) {
      validateSelections();
    }
  };

  const handleSave = () => {
    if (!validateSelections()) return;

    mutate({
      userTopicIds: selectedTopics,
      userTypeIds: selectedTypes,
      userRegionIds: selectedRegions,
      IsDefaultView: defaultView,
    });
  };

  return (
    <section>
      <Box p={4} mx="auto">
        <Grid container spacing={4}>
          {/* Types Section */}
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Which Types are you interested in?
            </Typography>
            {data?.types.map((region) => (
              <FormControlLabel
                key={region.id}
                control={
                  <Checkbox
                    value={region.id}
                    checked={selectedTypes.includes(region.id)}
                    onChange={handleTypeChange}
                    sx={{
                      color: primaryColor,
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={region.title}
              />
            ))}
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedTypes.length === data?.types.length}
                  onChange={handleSelectAllTypes}
                  sx={{
                    color: primaryColor,
                    "&.Mui-checked": {
                      color: primaryColor,
                    },
                  }}
                />
              }
              label="Check all"
            />
          </Grid>

          {/* Topics Section */}
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Which Topics are you interested in?
            </Typography>
            {data?.topics.map((topic) => (
              <FormControlLabel
                key={topic.id}
                control={
                  <Checkbox
                    value={topic.id}
                    checked={selectedTopics.includes(topic.id)}
                    onChange={handleTopicChange}
                    sx={{
                      color: primaryColor,
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={topic.title}
              />
            ))}
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedTopics.length === data?.topics.length}
                  onChange={handleSelectAllTopics}
                  sx={{
                    color: primaryColor,
                    "&.Mui-checked": {
                      color: primaryColor,
                    },
                  }}
                />
              }
              label="Check all"
            />
          </Grid>

          {/* Regions Section */}
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Which Regions are you interested in?
            </Typography>
            {data?.regions.map((region) => (
              <FormControlLabel
                key={region.id}
                control={
                  <Checkbox
                    value={region.id}
                    checked={selectedRegions.includes(region.id)}
                    onChange={handleRegionChange}
                    sx={{
                      color: primaryColor,
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label={region.title}
              />
            ))}
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedRegions.length === data?.regions.length}
                  onChange={handleSelectAllRegions}
                  sx={{
                    color: primaryColor,
                    "&.Mui-checked": {
                      color: primaryColor,
                    },
                  }}
                />
              }
              label="Check all"
            />
          </Grid>
        </Grid>
        <hr color="lightgray" style={{ marginTop: 8 }} />
        {/* Default View */}
        <FormControlLabel
          control={
            <Checkbox
              checked={defaultView}
              onChange={handleDefaultViewChange}
              sx={{
                color: primaryColor,
                "&.Mui-checked": {
                  color: primaryColor,
                },
              }}
            />
          }
          label="Make this my default view"
          sx={{ mt: 2 }}
        />

        {/* Buttons */}
        <Box mt={4} display="flex" justifyContent="end">
          <Button text="Back" backgroundColor="white" />
          <Button text="Save" onClick={handleSave} />
        </Box>
      </Box>
      <Toast key={toastKey} message={responseMessage} status={status} />
    </section>
  );
};

export default Preferences;
