import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, TextField, Typography, Autocomplete } from "@mui/material";
import Button from "../../common/Button";
import { getTimezoneOnboardedOrganisation } from "../../../store/api/organisation";

const Details = ({
  email,
  setEmail,
  location,
  setLocation,
  timeZone,
  setTimeZone,
  handleBack,
  handleNext,
}) => {
  const {
    data: timeZones,
    isLoading,
    isFetched,
  } = useQuery({
    queryKey: ["onboardedTimeZoneOrganisation"],
    queryFn: () => getTimezoneOnboardedOrganisation({}),
  });

  const isNextDisabled = !email || !location || !timeZone; // Disable Next if any field is empty

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
          backgroundColor: "#fff",
        }}
      >
        {/* Header */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            mb: 2,
            textAlign: "center",
          }}
        >
          Let’s grab a couple of details
        </Typography>

        {/* Form Container */}
        <Box
          component="form"
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr", sm: "auto 1fr" },
            gap: { xs: 2, sm: 3 },
            width: "100%",
            maxWidth: "600px",
            alignItems: "center",
          }}
        >
          {/* Email Field */}
          <Typography sx={{ color: "gray", textAlign: "right" }}>
            Email address
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Enter email"
            fullWidth
            type="email"
            required
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          {/* Country Field */}
          <Typography sx={{ color: "gray", textAlign: "right" }}>
            Location
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Enter Location"
            fullWidth
            required
            onChange={(e) => setLocation(e.target.value)}
            value={location}
          />

          {/* Timezone Field */}
          <Typography sx={{ color: "gray", textAlign: "right" }}>
            Timezone
          </Typography>
          <Autocomplete
            options={timeZones || []}
            getOptionLabel={(option) => option.title || ""}
            loading={isLoading}
            disabled={isLoading || !isFetched}
            value={timeZones?.find((tz) => tz.id === timeZone) || null}
            onChange={(event, newValue) => setTimeZone(newValue?.id || "")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Select Timezone"
                fullWidth
              />
            )}
          />
        </Box>

        {/* Subtitle */}
        <Typography
          variant="body2"
          sx={{
            color: "gray",
            mt: 2,
            textAlign: "center",
          }}
        >
          This will help with when emails are sent out.
        </Typography>
      </Box>
      {/* Navigation Buttons */}
      <Box sx={{ marginTop: 4 }} display={"flex"} justifyContent={"end"}>
        <Button
          onClick={handleNext}
          text="Next"
          disabled={isNextDisabled} // Disable "Next" until all fields are filled
        />
      </Box>
    </Box>
  );
};

export default Details;
