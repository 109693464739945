import React, { useState, useEffect } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams, useNavigate } from "react-router-dom";

import ScrollToTopButton from "../../common/GoTop";
import Loading from "../../common/Loading";
import TopContent from "./TopContent";
import PaginatedContent from "./PaginatedContent";
import FilterBar from "./FilterBar";
import ArticleCarouselAds from "./ArticleAds";
import FeaturedContent from "./FeaturedContent";
import SearchedContent from "./SearchPage";

import {
  getAllArticles,
  getSearchContentByUserTemplate,
  getUserSearchedContent,
} from "../../../store/api/content";
import { getOnboardedOrganisation } from "../../../store/api/organisation";

interface Topic {
  id: string;
  title: string;
}

interface SearchTemplate {
  topics: Topic[];
  types: any[];
  regions: any[];
}

const DashboardPage: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  // Existing state
  const [vendor, setVendor] = useState<string[]>([]);
  const [region, setRegion] = useState<string[]>([]);
  const [topic, setTopic] = useState<string[]>([]);
  const [type, setType] = useState<string[]>([]);
  const [usersIds, setUsersIds] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");
  const [isSearch, setIsSearch] = useState<boolean>(false);

  // New date range state
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const { data: searchedContent } = useQuery({
    queryKey: [
      "searchedContent",
      search,
      region,
      topic,
      type,
      startDate,
      endDate,
    ],
    queryFn: () =>
      getUserSearchedContent({
        searchTerm: search,
        usersIds: null,
        topicIds: topic.length ? topic : null,
        regionIds: region.length ? region : null,
        typeIds: type.length ? type : null,
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
      }),
    enabled: isSearch || search.length > 2,
  });

  const { data: filters } = useQuery<SearchTemplate>({
    queryKey: ["searchTemplate"],
    queryFn: () => getSearchContentByUserTemplate({}),
  });

  useEffect(() => {
    const topicParam = searchParams.get("topic");
    if (topicParam && filters?.topics) {
      const matchingTopic = filters.topics.find(
        (el) => el?.title === topicParam
      );
      setTopic(matchingTopic?.id ? [matchingTopic.id] : []);
      setIsSearch(true);
    }
  }, [searchParams, filters?.topics]);

  const { data, error, isLoading } = useQuery({
    queryKey: ["contentUser"],
    queryFn: () => getAllArticles({ continuationToken: "" }),
    enabled: !isSearch,
  });

  const {} = useQuery({
    queryKey: ["onboardedOrganisationDashboard"],
    queryFn: () => getOnboardedOrganisation(),
  });

  const handleTopicChange = (newTopics: string[]) => {
    setTopic(newTopics);
    navigate("");
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <ScrollToTopButton />
      <FilterBar
        region={region}
        setRegion={setRegion}
        vendor={vendor}
        setVendor={setVendor}
        topicList={filters?.topics}
        topic={topic}
        type={type}
        typeList={filters?.types}
        regionList={filters?.regions}
        setTopic={handleTopicChange}
        setType={setType}
        search={search}
        setIsSearch={setIsSearch}
        setSearch={setSearch}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      {isSearch || search.length > 2 ? (
        <SearchedContent
          searchedContent={searchedContent}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      ) : (
        <>
          <TopContent articles={data?.latestApproved} />
          {data?.latestFeatured?.length ? (
            <FeaturedContent articles={data?.latestFeatured} />
          ) : null}
          <PaginatedContent />
        </>
      )}
    </>
  );
};

export default DashboardPage;
