import React, { useEffect, useState } from "react";
import { ControlPoint, Delete, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Table from "../../common/Table";
import Toast from "../../common/Toast";

import Ads from "./Ads";
import AddEditModal from "./AddEdit";
import { columns } from "./columns";
import { useOrganisation } from "../../../hooks/useOrganisation";
import { getTextColor } from "../../../helpers";

const TabPanel = ({ value, index, children }) => {
  return value === index ? <div>{children}</div> : null;
};

const Component = ({
  tabIndex,
  index,
  type,
  getData,
  addFunction,
  editFunction,
  deleteFunction,
  restoreFunction,
  keytype,
}) => {
  const queryClient = useQueryClient();
  const { primaryColor } = useOrganisation();
  const textColor = getTextColor(primaryColor) || "black";

  const [openModal, setOpenModal] = useState(false);
  const [allData, setAllData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("DateCreated");
  const [isDescending, setIsDescending] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [status, setStatus] = useState(200);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [keytype, searchTerm, currentPage, sortBy, isDescending],
    queryFn: () => getData({ searchTerm, currentPage, sortBy, isDescending }),
    refetchOnWindowFocus: true,
    staleTime: 0,
  });

  const { mutate: addMutate } = useMutation({
    mutationFn: (title) => addFunction(title),
    onSuccess: (data) => {
      queryClient.refetchQueries(keytype);
      setStatus(200);
      setResponseMessage("Added successfullly");
    },
    onError: (error) => {
      setStatus(400);
      setResponseMessage("error accured");
    },
  });

  const { mutate: editMutate } = useMutation({
    mutationFn: ({ title, id }) => editFunction({ title, id }),
    onSuccess: (data) => {
      queryClient.refetchQueries(keytype);
      setStatus(200);
      setResponseMessage("edited successfully");
      setIsEdit(false);
      setEditData(null);
    },
    onError: (error) => {
      setStatus(400);
      setResponseMessage("error accured");
    },
  });
  const { mutate: deleteMutate } = useMutation({
    mutationFn: (ids) => deleteFunction(ids),
    onSuccess: (data) => {
      queryClient.refetchQueries(keytype);
      setSelectedIds([]);
      setStatus(200);
      setResponseMessage("archived successfully");
    },
    onError: (error) => {
      setStatus(400);
      setResponseMessage("error accured");
    },
  });
  const { mutate: restoreMutate } = useMutation({
    mutationFn: (ids) => restoreFunction(ids),
    onSuccess: (data) => {
      queryClient.refetchQueries(keytype);
      setSelectedIds([]);
      setStatus(200);
      setResponseMessage("restored successfully");
    },
    onError: (error) => {
      setStatus(400);
      setResponseMessage("error accured");
    },
  });
  useEffect(() => {
    if (!isLoading) {
      setTotalPage(data?.totalPages);
      setAllData(data?.paginatedContent);
    }
  }, [isLoading, data]);
  useEffect(() => {
    setCurrentPage(1);
  }, [tabIndex]);

  const handleAdd = () => {
    setEditData(null);
    setOpenModal(true);
  };

  const handleEdit = (data) => {
    setIsEdit(true);
    setEditData(data);
    setOpenModal(true);
  };
  const handleSelect = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };
  const handleSelectAll = () => {
    if (selectedIds.length === allData.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(allData.map((el) => el.id));
    }
  };
  const handleOpenDelete = () => {
    setDeleteModal(true);
  };
  const handleCloseDelete = () => {
    setDeleteModal(false);
  };
  const handleDelete = () => {
    deleteMutate([...selectedIds]);
    handleCloseDelete();
  };
  const handleStatusChange = (id, status) => {
    if (status === "Deleted") {
      deleteMutate([id]);
    } else {
      restoreMutate([id]);
    }
  };
  return (
    <TabPanel value={tabIndex} index={index}>
      {tabIndex !== 3 ? (
        <Box>
          <Grid
            container
            sm={12}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box
              alignItems={"center"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <TextField
                    variant="outlined"
                    placeholder="Search"
                    value={searchItem}
                    sx={{ flexGrow: 1 }}
                    onChange={(e) => {
                      setSearchItem(e.target.value);
                    }}
                    style={{ border: "1px solid black", borderRadius: "8px" }}
                  />
                  <IconButton
                    onClick={() => {
                      setSearchTerm(searchItem);
                    }}
                  >
                    <Search />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Button
                  variant="raised"
                  endIcon={<ControlPoint />}
                  sx={{ backgroundColor: primaryColor, color: textColor }}
                  onClick={handleAdd}
                >
                  Add {type}
                </Button>
              </Grid>{" "}
            </Box>
            <Box>
              <Button
                variant="outlined"
                startIcon={<Delete />}
                sx={{ color: "#888" }}
                onClick={handleOpenDelete}
                disabled={selectedIds.length === 0}
              >
                Archive content
              </Button>
            </Box>
          </Grid>
          <Table
            data={allData}
            columns={columns({
              setSortBy,
              setIsDescending,
              isDescending: false,
              handleEdit,
              setIsEdit,
              handleSelectAll,
              selectedIds,
              handleSelect,
              handleStatusChange,
              primaryColor,
            })}
            totalPage={totalPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
          <AddEditModal
            open={openModal}
            handleClose={() => setOpenModal(false)}
            type={type}
            initialData={editData}
            isEdit={isEdit}
            addMutate={addMutate}
            editsMutate={editMutate}
          />
          <Dialog
            open={deleteModal}
            onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to archive the selected items?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Archiving these items will remove them from the available
                filtering options. This will not affect content which has
                already had these filters applied.
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseDelete} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="error" autoFocus>
                Archive
              </Button>
            </DialogActions>
          </Dialog>
          <Toast message={responseMessage} status={status} />
        </Box>
      ) : (
        <Ads />
      )}
    </TabPanel>
  );
};

export default Component;
