import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const FileViewerModal = ({ open, onClose, fileData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const getFileType = (url) => {
    const extension = url?.split(".").pop()?.toLowerCase();
    return extension;
  };

  const handleDownload = () => {
    window.open(
      fileData.fileUrl +
        `?${"sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D"}`,
      "_blank"
    );
  };

  const renderFileContent = () => {
    const fileType = getFileType(fileData?.fileUrl);

    switch (fileType) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "400px",
            }}
          >
            <img
              src={`${
                fileData.fileUrl
              }?${"sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D"}`}
              alt={fileData.title}
              style={{ maxWidth: "100%", maxHeight: "70vh" }}
              onLoad={() => setIsLoading(false)}
              onError={() => {
                setIsLoading(false);
                setError(true);
              }}
            />
          </Box>
        );

      case "pdf":
        return (
          <Box sx={{ height: "70vh" }}>
            <iframe
              src={`${
                fileData.fileUrl
              }?${"sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D"}`}
              title={fileData.title}
              width="100%"
              height="100%"
              onLoad={() => setIsLoading(false)}
              onError={() => {
                setIsLoading(false);
                setError(true);
              }}
            />
          </Box>
        );

      case "mp4":
      case "mov":
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              minHeight: "400px",
            }}
          >
            <video
              controls
              style={{ maxWidth: "100%", maxHeight: "70vh" }}
              onLoadedData={() => setIsLoading(false)}
              onError={() => {
                setIsLoading(false);
                setError(true);
              }}
            >
              <source
                src={`${
                  fileData.fileUrl
                }?${"sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D"}`}
                type={`video/${fileType}`}
              />
              Your browser does not support the video tag.
            </video>
          </Box>
        );

      case "xlsx":
      case "xls":
      case "doc":
      case "docx":
      case "ppt":
      case "pptx":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "400px",
              gap: 2,
            }}
          >
            <Typography variant="h6" color="text.secondary">
              Preview not available for {fileType.toUpperCase()} files
            </Typography>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleDownload}
            >
              Download File
            </Button>
          </Box>
        );

      case "zip":
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "400px",
              gap: 2,
            }}
          >
            <Typography variant="h6" color="text.secondary">
              ZIP file cannot be previewed
            </Typography>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleDownload}
            >
              Download ZIP
            </Button>
          </Box>
        );

      default:
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "400px",
            }}
          >
            <ErrorOutlineIcon
              sx={{ fontSize: 48, color: "text.secondary", mb: 2 }}
            />
            <Typography color="text.secondary">
              Unable to preview this file type
            </Typography>
          </Box>
        );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="div" sx={{ pr: 4 }}>
            {fileData?.title}
          </Typography>
          <Box>
            <IconButton onClick={handleDownload} sx={{ mr: 1 }}>
              <DownloadIcon />
            </IconButton>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "400px",
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {error ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "400px",
              gap: 2,
            }}
          >
            <ErrorOutlineIcon sx={{ fontSize: 48, color: "error.main" }} />
            <Typography color="error">
              Error loading file. Please try downloading instead.
            </Typography>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleDownload}
            >
              Download File
            </Button>
          </Box>
        ) : (
          renderFileContent()
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileViewerModal;
