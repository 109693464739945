import React, { useState, MouseEvent } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import HomeIcon from "@mui/icons-material/Home";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import { getRole } from "../../../helpers/auth";
import { roles } from "../../../constants/routes";
import { useOrganisation } from "../../../hooks/useOrganisation";
import { getTextColor } from "../../../helpers";
import { Container } from "@mui/material";
import "./style.css";

const NavBar = () => {
  const navigate = useNavigate();
  const companyName = localStorage.getItem("companyName");
  const queryClient = useQueryClient();

  const { primaryColor, logoLink } = useOrganisation();
  const { pathname } = useLocation();
  const role = getRole();
  const [anchorEl, setAnchorEl] = useState(null);
  const textColor = getTextColor(primaryColor);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.clear();
    queryClient.refetchQueries({
      queryKey: ["onboardedOrganisation"],
    });
    navigate("/");
  };
  const handleMenuRoute = (route) => {
    navigate(route);
    handleMenuClose();
  };
  return pathname.includes("onboarding") ? (
    <Container maxWidth="lg" className="sectionCenter">
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar sx={{ justifyContent: "flex-end" }}>
          <Box sx={{ display: "flex" }}>
            <MenuItem onClick={handleLogout}>
              <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
              Log out
            </MenuItem>
          </Box>
        </Toolbar>
      </AppBar>
    </Container>
  ) : (
    <Container
      maxWidth="lg"
      className="sectionCenter"
      style={{ paddingLeft: 0 }}
    >
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        style={{ paddingLeft: 0 }}
      >
        <Toolbar
          sx={{ justifyContent: "space-between", paddingLeft: 0 }}
          // style={{ paddingLeft: 0 }}
        >
          {/* Logo */}

          {logoLink ? (
            <Box
              onClick={() =>
                role === roles.SuperAdmin
                  ? navigate(`/${companyName}/admin-dashboard`)
                  : navigate(`/${companyName}/dashboard`)
              }
              component="img"
              src={`${logoLink}?${"sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D"}`}
              alt="logo"
              sx={{
                maxHeight: "100px" /* Prevents logo from being too large */,
                maxWidth: "200px" /* Prevents logo from being too wide */,
                width: "auto",
                height: "auto",
                objectFit: "contain",
                display: {
                  xs: "none",
                  sm: "block",
                },
                cursor: "pointer",
              }}
            />
          ) : (
            <Box
              onClick={() =>
                role === roles.SuperAdmin
                  ? navigate(`/${companyName}/admin-dashboard`)
                  : navigate(`/${companyName}/dashboard`)
              }
              component="img"
              src="/favicon/Komz_Logo_GreenBlock.png"
              alt="Komz_logo"
              sx={{
                maxHeight: "100px",
                maxWidth: "200px",
                width: "auto",
                height: "auto",
                objectFit: "contain",
                display: {
                  xs: "none",
                  sm: "block",
                },
                cursor: "pointer",
              }}
            />
          )}

          <Box sx={{ display: "flex" }}>
            <Box className="header-left" style={{ background: primaryColor }}>
              <IconButton
                onClick={() =>
                  role === roles.SuperAdmin
                    ? navigate(`/${companyName}/admin-dashboard`)
                    : navigate(`/${companyName}/dashboard`)
                }
              >
                <HomeIcon
                  sx={{
                    color: textColor || "black",
                  }}
                />
              </IconButton>
            </Box>
            {/* Settings with Dropdown */}
            {role !== roles.SuperAdmin && (
              <Box className="header-left" style={{ background: primaryColor }}>
                <IconButton color="inherit" onClick={handleMenuOpen}>
                  <Typography
                    variant="body2"
                    sx={{ ml: 1 }}
                    color={textColor || "black"}
                  >
                    Your Settings
                  </Typography>
                  <PersonOutlineIcon
                    sx={{
                      color: textColor || "black",
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  PaperProps={{
                    elevation: 4,
                    sx: {
                      width: 200,
                    },
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      handleMenuRoute(`/${companyName}/settings/account`)
                    }
                  >
                    Account
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleMenuRoute(`/${companyName}/settings/preferences`)
                    }
                  >
                    Preference Center
                  </MenuItem>

                  <Divider />
                  <MenuItem onClick={handleLogout}>
                    <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
                    Log out
                  </MenuItem>
                </Menu>
              </Box>
            )}
            {role !== roles.Reader && (
              <IconButton
                color="inherit"
                // href={`/${companyName}/admin-dashboard`}
                onClick={() => navigate(`/${companyName}/admin-dashboard`)}
                className="header-left"
                style={{
                  background: primaryColor,
                  borderRadius: 4,
                }}
              >
                <Typography
                  variant="body2"
                  noWrap
                  sx={{
                    ml: 1,
                    // fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".2rem",
                    color: textColor || "black",
                    textDecoration: "none",
                  }}
                  className="header-left"
                >
                  Admin
                </Typography>
              </IconButton>
            )}
            {role === roles.SuperAdmin && (
              <MenuItem onClick={handleLogout}>
                <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
                Log out
              </MenuItem>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Container>
  );
};

export default NavBar;
