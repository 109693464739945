import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useMutation } from "@tanstack/react-query";
import {
  Box,
  TextField,
  Typography,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import { addBroadcastContent } from "../../../store/api/content";
import Button from "../../common/Button";

const SendCustomEmail = () => {
  const [subject, setSubject] = useState("");
  const [contentHtmlSubject, setContentHtmlsubject] = useState("");
  const [contentTextSubject, setContentTextsubject] = useState("");
  const [errors, setErrors] = useState({ subject: false, content: false });

  // State for Snackbar/Toast
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: addBroadcastContent,
    onSuccess: () => {
      setToast({
        open: true,
        message: "Broadcast content sent successfully!",
        severity: "success",
      });
      // Reset form after successful submission
      setSubject("");
      setContentHtmlsubject("");
    },
    onError: (error) => {
      setToast({
        open: true,
        message: "Failed to send broadcast content",
        severity: "error",
      });
      console.error(error);
    },
  });

  const handleSend = () => {
    if (!subject || !contentHtmlSubject) {
      setErrors({
        subject: !subject,
        content: !contentHtmlSubject,
      });
      return;
    }

    // Extract plain text content for `contentTextsubject`
    const plainText = contentHtmlSubject.replace(/<[^>]*>/g, ""); // Remove HTML tags
    setContentTextsubject(plainText);

    // Data to be sent to the API
    const data = {
      subject,
      contentText: plainText,
      contentHtml: contentHtmlSubject,
    };

    mutate(data);
  };

  // Handle closing the toast
  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToast({ ...toast, open: false });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
        }}
      >
        <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
          Send Custom Email
        </Typography>

        <Box
          component={Paper}
          elevation={3}
          sx={{
            width: "100%",
            maxWidth: "600px",
            p: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <TextField
            label="Subject"
            variant="outlined"
            fullWidth
            required
            error={errors.subject}
            helperText={errors.subject ? "Subject is required" : ""}
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
              setErrors({ ...errors, subject: false });
            }}
          />

          <ReactQuill
            theme="snow"
            value={contentHtmlSubject}
            onChange={(value) => {
              setContentHtmlsubject(value);
              setErrors({ ...errors, content: false });
            }}
            placeholder="Write your email content here..."
            style={{ height: "200px" }}
          />

          {errors.content && (
            <Typography color="error">Content is required</Typography>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mt: 2,
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            <Button
              text={isLoading ? "Sending..." : "Send ALL"}
              style={{}}
              onClick={() => handleSend()}
            />
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={toast.open}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseToast}
          severity={toast.severity}
          sx={{ width: "100%" }}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SendCustomEmail;
