import React from "react";
import PreferencesCenterMerged from "./PreferenceCenter";

const PreferencesCenter = () => {
  return (
    <section className="sectionCenter">
      <PreferencesCenterMerged />
    </section>
  );
};

export default PreferencesCenter;
