import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import UploadResource from "./AddReource";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteResource,
  getAllResources,
} from "../../../../store/api/resources";
import FileViewerModal from "./ViewFile";

const ResourceList = () => {
  const queryClient = useQueryClient();

  const [searchQuery, setSearchQuery] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);

  const { data: resources, isLoading } = useQuery({
    queryKey: ["resourcesList"],
    queryFn: () => getAllResources({ IsOnlyImage: false }),
  });
  const { mutate: deleteResourceMutate } = useMutation({
    mutationFn: deleteResource,
    onSuccess: (data) => {
      queryClient.refetchQueries("resourcesList");
    },
    onError: () => {
      alert("Failed to delete content");
    },
  });

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleView = (resource) => {
    setSelectedResource(resource);
    setModalOpen(true);
  };

  const handleDelete = (resourceId) => {
    deleteResourceMutate(resourceId);
  };

  const formatFileSize = (size) => {
    return size.toFixed(3);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h5" component="h1">
          Resources
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ bgcolor: "black", "&:hover": { bgcolor: "grey.900" } }}
          onClick={() => setAddOpen(true)}
        >
          ADD FILES
        </Button>
      </Box>
      <UploadResource open={addOpen} onClose={() => setAddOpen(false)} />
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        Allowed file types: pdf, docx, doc, xlsx, xls, jpg, png, zip, ppt, pptx,
        mp4, mov
      </Typography>

      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        You are currently using:{" "}
        {/* {resources?.reduce((acc, curr) => acc + curr.size, 0).toFixed(2)} mb of
        disk */}
      </Typography>

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search"
        value={searchQuery}
        onChange={handleSearch}
        sx={{ mb: 3 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="right">File Size (MB)</TableCell>
              <TableCell align="right">Date Added</TableCell>
              <TableCell align="right">View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources?.paginatedContent.map((resource) => (
              <TableRow key={resource.id}>
                <TableCell>{resource.title}</TableCell>
                <TableCell align="right">
                  {formatFileSize(resource.fileSize)}
                </TableCell>
                <TableCell align="right">{resource.dateAdded}</TableCell>
                <TableCell align="right">
                  <IconButton size="small" onClick={() => handleView(resource)}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleDelete(resource.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FileViewerModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        fileData={selectedResource}
      />
    </Box>
  );
};

export default ResourceList;
