import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Box, Typography } from "@mui/material";

import AddFeedModal from "./AddFeed";

import Table from "../../common/Table";
import Button from "../../../compnents/common/Button";
import {
  deleteFeed,
  getAllFeed,
  getFeedTemplateToUpdate,
  restoreFeed,
} from "../../../store/api/feed";
import { useOrganisation } from "../../../hooks/useOrganisation";

import { columns } from "./columns";

const Feed = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { primaryColor, companyName } = useOrganisation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feeds, setFeeds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalPages, settotalPages] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [idFeed, setIdFeed] = useState("");

  const { data, isLoading } = useQuery({
    queryKey: ["RssFeedList", searchTerm, currentPage],
    queryFn: () =>
      getAllFeed({
        searchTerm,
        pageNumber: currentPage,
      }),
  });
  const { data: feedToEdit } = useQuery({
    queryKey: ["RssFeed"],
    queryFn: () => getFeedTemplateToUpdate(idFeed),
    enabled: isEdit && idFeed !== "",
  });

  const { mutate: archiveFeed } = useMutation({
    mutationFn: ({ id }) => deleteFeed(id),
    onSuccess: (data) => {
      queryClient.refetchQueries({
        queryKey: ["RssFeedList"],
      });
    },
    onError: (error) => {},
  });

  const { mutate: activateFeed } = useMutation({
    mutationFn: ({ id }) => restoreFeed(id),
    onSuccess: (data) => {
      queryClient.refetchQueries({
        queryKey: ["RssFeedList"],
      });
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (!isLoading) {
      setFeeds(data?.paginatedContent || []);
      setCurrentPage(data?.pageNumber || 0);
      settotalPages(data?.totalPages);
    }
  }, [isLoading, data]);

  const handleDelete = (status, id) => {
    if (status === "Y") {
      archiveFeed({ id });
    } else {
      activateFeed({ id });
    }
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setIsEdit(false);
    setIdFeed("");
  };

  return (
    <section>
      <Box sx={{ width: "100%", padding: 2 }}>
        {/* Header */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h5" fontWeight="bold">
            Aggregate
          </Typography>

          <div>
            <Button text="Add feed" onClick={handleOpen} />

            <AddFeedModal
              open={isModalOpen}
              handleClose={handleClose}
              feedToEdit={feedToEdit}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              idFeed={idFeed}
            />
          </div>
        </Box>

        <Table
          data={feeds}
          columns={columns({
            primaryColor,
            setCurrentPage,
            handleDelete,
            setIsEdit,
            handleOpen,
            setIdFeed,
            navigate,
            companyName,
          })}
          totalPage={totalPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Box>
    </section>
  );
};

export default Feed;
