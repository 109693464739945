import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import { columns } from "./columns";
import ListTable from "../../common/Table";

import {
  deleteOrganisation,
  getAllOrganisation,
} from "../../../store/api/organisation";
import AddEditModal from "./AddEditModal";
import { useOrganisation } from "../../../hooks/useOrganisation";

const AllOrganisationsPage = () => {
  const queryClient = useQueryClient();
  const { primaryColor } = useOrganisation();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("DateCreated");
  const [isDescending, setIsDescending] = useState(true);
  const [addModal, setAddModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [organisationId, setOrganisationId] = useState([]);
  const [isEdit, setisEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: [
      "allOrganisation",
      searchTerm,
      currentPage,
      sortBy,
      isDescending,
    ],
    queryFn: () =>
      getAllOrganisation({ searchTerm, currentPage, sortBy, isDescending }),
    refetchOnWindowFocus: true,
  });

  const { mutate: deleteMutate } = useMutation({
    mutationFn: () => deleteOrganisation([...selectedIds]),
    onSuccess: (data) => {
      // setMessage("");
      // setStatus(200);
      // setResponseMessage("Users delete it");
      queryClient.refetchQueries("allOrganisation");
      setSelectedIds([]);
    },
    onError: (error) => {},
  });
  useEffect(() => {
    if (!isLoading) {
      // setAllUsers(data?.paginatedContent);
      setTotalPage(data?.totalPages);
    }
  }, [isLoading, data]);
  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchItem(term);
  };
  const handleItemSearch = () => {
    setSearchTerm(searchItem);
  };
  const handleSelectAll = () => {
    if (selectedIds.length === data.paginatedContent.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(data.paginatedContent.map((user) => user.id));
    }
  };
  const handleSelect = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };
  const handleEditOrganisation = (id) => {
    setOrganisationId(id);
    setisEdit(true);
    setAddModal(true);
  };
  const handleDelete = () => {
    deleteMutate();
    setOpenDelete(false);
  };
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  return (
    <div>
      <h3>View all Orgnanisations</h3>
      <Box>
        <Grid container xs={12} flex={1} justifyContent={"space-between"}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              marginBottom: 2,
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search"
              value={searchItem}
              sx={{ flexGrow: 1 }}
              onChange={handleSearch}
              style={{ border: "1px solid black", borderRadius: "8px" }}
            />
            <IconButton onClick={handleItemSearch}>
              <SearchIcon />
            </IconButton>
            <Button
              variant="raised"
              endIcon={<ControlPointIcon />}
              sx={{
                backgroundColor: primaryColor,
                color: "#333",
                marginRight: 2,
              }}
              onClick={() => setAddModal(true)}
            >
              Add organisation
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 2,
            }}
          >
            <Button
              variant="outlined"
              startIcon={<DeleteIcon />}
              sx={{
                color: "black",
                border: `1 solid ${primaryColor}`,
              }}
              onClick={handleOpenDelete}
              disabled={selectedIds.length === 0}
            >
              Delete organisation
            </Button>
          </Box>
        </Grid>
      </Box>
      <ListTable
        data={data?.paginatedContent || []}
        columns={columns({
          setSortBy,
          setIsDescending,
          isDescending,
          handleEditOrganisation,
          handleSelectAll,
          selectedIds,
          handleSelect,
        })}
        isLoading={isLoading}
        totalPage={totalPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />

      <AddEditModal
        open={addModal}
        handleClose={() => setAddModal(false)}
        isEdit={isEdit}
        organisationId={organisationId}
      />
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete the selected organisations?"}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting these users cannot be undone. Please confirm if you want to
            proceed.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AllOrganisationsPage;
