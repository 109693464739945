import React, { useState } from "react";
import { Box, Button, Typography, Dialog, DialogContent } from "@mui/material";

const ArticleResources = ({ files, onFileClick, primaryColor }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const getResourceType = (contentType) => {
    if (contentType.startsWith("video/")) return "Video";
    if (contentType.startsWith("image/")) return "Image";
    if (contentType.startsWith("application/pdf")) return "PDF";
    if (
      contentType.startsWith("application/msword") ||
      contentType.startsWith(
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      )
    )
      return "Word Doc";
    if (
      contentType.startsWith("application/vnd.ms-excel") ||
      contentType.startsWith(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    )
      return "Excel";
    if (
      contentType.startsWith("application/vnd.ms-powerpoint") ||
      contentType.startsWith(
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      )
    )
      return "PowerPoint";
    if (contentType.startsWith("text/")) return "Text";
    if (contentType.startsWith("audio/")) return "Audio";
    return "File";
  };

  const handleFileClick = (file) => {
    if (
      file.contentType.startsWith("image/") ||
      file.contentType.startsWith("application/pdf")
    ) {
      setSelectedFile(file);
      setOpenDialog(true);
    } else {
      onFileClick(file);
    }
  };

  const getFileUrl = (file) => {
    return `${file.url}?sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D`;
  };

  const getFileButton = (file) => {
    const baseStyles = {
      marginRight: 2,
      marginBottom: 1,
      textTransform: "none",
      borderRadius: "4px",
      padding: "6px 16px",
      display: "flex",
      alignItems: "center",
      gap: 1,
    };

    const resourceType = getResourceType(file.contentType);
    const isVideo = resourceType === "Video";

    return (
      <Button
        variant="contained"
        onClick={() => handleFileClick(file)}
        sx={{
          ...baseStyles,
          backgroundColor: isVideo ? primaryColor : "#F3F4F6",
          color: "black",
          "&:hover": {
            backgroundColor: isVideo ? primaryColor : "#E5E7EB",
          },
        }}
      >
        {`View ${resourceType} >`}
      </Button>
    );
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedFile(null);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {files?.map((file, index) => (
          <Box
            key={file.id || index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {getFileButton(file)}
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                maxWidth: 120,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: "text.secondary",
              }}
            >
              {file.name}
            </Typography>
          </Box>
        ))}
      </Box>

      <Dialog open={openDialog} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogContent
          sx={{ p: 0, height: "80vh", bgcolor: "background.paper" }}
        >
          {selectedFile?.contentType.startsWith("image/") ? (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "grey.900",
                p: 2,
              }}
            >
              <img
                src={getFileUrl(selectedFile)}
                alt={selectedFile.name}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          ) : selectedFile?.contentType.startsWith("application/pdf") ? (
            <iframe
              src={`${getFileUrl(selectedFile)}#toolbar=1&navpanes=1`}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
              title="PDF Viewer"
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ArticleResources;
