import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkTokenExpiration } from "../helpers/auth";
import { refreshToken } from "../store/api/users";
// import { jwtDecode, jwtVerify } from "jose"; // Use jose library for signature verification

// const verifyTokenSignature = async (token) => {
//   console.log("here");
//   const secret = new TextEncoder().encode(
//     process.env.REACT_APP_TOKEN_SECRET_KEY
//   ); // Use the secret key from .env
//   try {
//     const { payload } = await jwtVerify(token, secret);
//     return payload; // Return the decoded payload if the signature is valid
//   } catch (error) {
//     console.error("Invalid token signature:", error);

//     throw new Error("Invalid token signature");
//   }
// };

const checkAuth = async () => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  const refreshTokenData = localStorage
    .getItem("refreshToken")
    ?.replace(/^"|"$/g, "");

  // Validate token format
  if (!token || token.split(".").length !== 3) {
    throw new Error("Invalid token format");
  }

  try {
    // Verify token signature
    // const decoded = await verifyTokenSignature(token);
    // console.log("Decoded token:", decoded);

    const isTokenExpired = checkTokenExpiration(token);
    console.log("Is token expired?", isTokenExpired);

    if (isTokenExpired) {
      if (!refreshTokenData) {
        console.log("No refresh token available");
        throw new Error("No refresh token available");
      }

      // Refresh the token
      console.log("Refreshing token...");
      const response = await refreshToken({
        refreshTokenData,
      });
      console.log(response);
      if (!response.accessToken || !response.refreshToken) {
        throw new Error("Invalid response from refreshToken API");
      }

      localStorage.setItem("token", JSON.stringify(response.accessToken));
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(response.refreshToken)
      );
      return true;
    }
    return true;
  } catch (error) {
    throw error;
  }
};

export const useCheckAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  // Use a query to check auth status
  const { data, refetch } = useQuery({
    queryKey: ["checkAuth", token, location],
    queryFn: checkAuth,
    refetchOnWindowFocus: false, // Prevent refetching on window focus
    staleTime: 1800000, // 30 minutes
    cacheTime: 1800000, // 30 minutes
    enabled: false, // Disable automatic refetching on mount
    onError: (error) => {
      console.error("Authentication error:", error);
      // Clear storage and redirect to login
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("companyName");
      navigate("/"); // Redirect to login page
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 1800000); // Refetch every 30 minutes
    refetch();

    return () => clearInterval(intervalId);
  }, [location.pathname, refetch]);

  return { isAuthenticated: data, refetch };
};
