import React from "react";
import { Container, Typography, Box, Grid, Chip } from "@mui/material";
import Button from "../../../common/Button";
import { getRole } from "../../../../helpers/auth";
import { roles } from "../../../../constants/routes";
import { useOrganisation } from "../../../../hooks/useOrganisation";

const ContentPreview = ({
  setStep,
  step,
  content,
  image,
  title,
  topics,
  scheduledDate,
  handleAddContent,
  modal,
  isLoadAddContent,
  publish,
}) => {
  const role = getRole(); //TODO create custome hooks getrole and everything related to organisation&roles
  const { primaryColor } = useOrganisation();
  return (
    <Container maxWidth="md">
      <Box
        display={"flex"}
        justifyContent={"center"}
        sx={{ textAlign: "center" }}
      >
        <Button
          text="Back"
          backgroundColor="white"
          onClick={() => setStep(step - 1)}
        />
        <Button
          text={"Submit for approval"}
          onClick={() => {
            if (scheduledDate) {
              handleAddContent({ publish: false });
            }
          }}
          disabled={isLoadAddContent}
          isLoading={isLoadAddContent && !publish}
        />
        {(roles.Admin === role || roles.Editor === role) && (
          <Button
            text={"Publish"}
            onClick={() => {
              if (scheduledDate) {
                handleAddContent({ publish: true });
              }
            }}
            disabled={isLoadAddContent}
            isLoading={isLoadAddContent && publish}
          />
        )}
      </Box>

      <Box sx={{ backgroundColor: "#f9f9f9", padding: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          Komz Channel |{" "}
          <Typography variant="body2">
            {new Date(scheduledDate + ":00").toString()}
          </Typography>
        </Typography>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                width: "100%",
                height: 150,
              }}
            >
              {modal ? (
                <img
                  src={image}
                  alt="Preview"
                  style={{ width: "100%", height: "auto" }}
                />
              ) : image?.contentType ? (
                <img
                  src={`data:${image?.contentType};base64,${image?.fileData}`}
                  alt="Preview"
                  style={{ width: "100%", height: "auto" }}
                />
              ) : (
                <img
                  src={"https://static.thenounproject.com/png/1765551-200.png"}
                  alt="Preview"
                  style={{ width: "100%", height: "auto" }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              variant="body1"
              sx={{
                wordBreak: "break-word", // Ensures long words break to the next line
                overflowWrap: "break-word", // Handles wrapping for long unbroken strings
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </Typography>

            <Box sx={{ marginTop: 3 }}>
              {topics.map((el) => (
                <Chip
                  label={el.title}
                  sx={{
                    backgroundColor: primaryColor,
                    color: "black",
                    margin: 1,
                  }}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ContentPreview;
