import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Box, Grid } from "@mui/material";
import ContentType from "./ContentType";
import ContentForm from "./ContentForm";
import ContentURL from "./ContentURL";
import ContentOption from "./ContentOption";
import ContentPreview from "./ContentPreview";
import AddResource from "./Resources";

import {
  addArticle,
  approveContent,
  editArticle,
  extractContent,
  getArticleByid,
  getUpdateArticleTemplateByid,
} from "../../../store/api/content";
import {
  convertImageUrlToBase64,
  downloadImage,
} from "../../../helpers/validator";
import "./style.css";

const AddContentPage = () => {
  const navigate = useNavigate();
  const { contentId } = useParams();
  const companyName = localStorage.getItem("companyName");

  const [step, setStep] = useState(0);
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [topics, setTopics] = useState([]);
  const [types, setTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [image, setImage] = useState(null);
  const [files, setFiles] = useState([]);
  const [imageList, setimageList] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [content, setContent] = useState("");
  const [summaryError, setSummaryError] = useState(false);
  const [isContentFromUrl, setIsContentFromUrl] = useState(false);
  const [url, setURL] = useState("");
  const [shareable, setShareable] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [newsLetter, setNewsLetter] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [publish, setPublish] = useState(false);

  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["articleToEdit", contentId],
    queryFn: () => getArticleByid({ id: contentId }),
    enabled: !!contentId,
  });

  const { data: articleToEdit } = useQuery({
    queryKey: ["DataForarticleToEdit", contentId],
    queryFn: () => getUpdateArticleTemplateByid({ id: contentId }),
    enabled: !!contentId,
  });

  const { mutate: approveMutate } = useMutation({
    mutationFn: (data) => approveContent({ contentId: data }),
    onSuccess: (data) => {},
    onError: (error) => {},
  });
  const { mutate, isPending } = useMutation({
    mutationFn: extractContent,
    onSuccess: async (data) => {
      try {
        console.log(data);
        if (data.mainImageUrls?.[0]) {
          try {
            // Attempt to convert the image URL to Base64
            const mainImageResult = await convertImageUrlToBase64(
              data.mainImageUrls[0]
            );
            setImage(mainImageResult);
          } catch (error) {
            console.error("Failed to convert image to Base64:", error);
            // If Base64 conversion fails, trigger auto-download
            downloadImage(data.mainImageUrls[0]);
            setImageError(true);
          }
        }

        if (data.mainImageUrls?.length > 0) {
          setimageList(data.mainImageUrls);
        }

        if (data.title) {
          setTitle(data.title);
        } else {
          setTitleError(true);
          setTitle("");
        }

        if (data.summary) {
          setContent(data.summary);
        } else {
          setSummaryError(true);
          setContent("");
        }

        setStep(2);
      } catch (error) {
        setImageError(true);
        setImage(null);
        console.error("Error processing images:", error);
      }
    },
    onError: () => {
      setImageError(true);
      setTitleError(true);
      setSummaryError(true);
    },
  });

  const { mutate: postContentMutate, isPending: isLoadAddContent } =
    useMutation({
      mutationFn: addArticle,
      onSuccess: (data) => {
        if (publish) {
          approveMutate(data);
        }
        navigate(`/${companyName}/admin-dashboard`);
      },
      onError: (error) => {
        console.log(error);
        alert("Failed to add content");
      },
    });
  const { mutate: updateContentMutate } = useMutation({
    mutationFn: editArticle,
    onSuccess: (data) => {
      if (publish) {
        approveMutate(contentId);
      }
      navigate(`/${companyName}/admin-dashboard`);
    },
    onError: () => {
      alert("Failed to add content");
    },
  });

  useEffect(() => {
    if (data && articleToEdit) {
      setTitle(articleToEdit.title || "");
      setTopics(articleToEdit?.topics.filter((el) => el.isSelected) || []);
      setRegions(articleToEdit?.regions.filter((el) => el.isSelected) || []);
      setTypes(articleToEdit?.types.filter((el) => el.isSelected) || []);
      setImage(articleToEdit?.mainImage || null);
      setFiles(articleToEdit?.files);
      setContent(articleToEdit.contentText || "");
      setScheduledDate(articleToEdit?.scheduledDate);
      setAuthor(articleToEdit.author || "");
      setEndDate(articleToEdit?.endDate);
      setShareable(articleToEdit?.shareable);
      setFeatured(articleToEdit?.featured);
      setNewsLetter(articleToEdit?.newsLetter);
    }
  }, [data, contentId, articleToEdit]);

  const SubmitForm = () => {
    setIsContentFromUrl(true);
    mutate({ url });
  };

  const handleAddContent = async ({ publish }) => {
    try {
      // Format the files array based on whether it's a new or existing resource
      const formattedFiles = files.map((file) => {
        if (file.isNew) {
          return {
            file: {
              fileName: file.fileName,
              contentType: file.contentType,
              fileData: file.fileData,
            },
          };
        } else {
          // Existing file (include ID)
          return {
            id: file.id,
            file: file.fileData
              ? {
                  // File has been updated, include file data
                  fileName: file.fileName,
                  contentType: file.contentType,
                  fileData: file.fileData,
                }
              : null, // File hasn't been updated, set file to null
          };
        }
      });

      // Prepare the payload
      const payload = contentId
        ? {
            title,
            author,
            contentText: content,
            isContentFromUrl,
            contentUrl: url,
            shareable,
            featured,
            newsLetter,
            scheduledDate,
            endDate,
            contentTopicIds: topics.map((el) => el.id),
            contentRegionIds: regions.map((el) => el.id),
            contentTypeIds: types.map((el) => el.id),
            mainImage: image,
            files: formattedFiles,
          }
        : {
            title,
            author,
            contentText: content,
            isContentFromUrl,
            contentUrl: url,
            shareable,
            featured,
            newsLetter,
            scheduledDate,
            endDate,
            contentTopicIds: topics.map((el) => el.id),
            contentRegionIds: regions.map((el) => el.id),
            contentTypeIds: types.map((el) => el.id),
            mainImage: image,
            files: files,
          };

      if (contentId) {
        // Editing an existing article
        setPublish(publish);
        updateContentMutate({
          contentId,
          ...payload,
          mainImage: image.fileData
            ? {
                id: image.id,
                file: {
                  fileName: image.fileName,
                  contentType: image.contentType,
                  fileData: image.fileData,
                },
              }
            : {
                id: image.id,
                file: null,
              },
        });
      } else {
        // Adding a new article
        setPublish(publish);
        postContentMutate(payload);
      }
    } catch (error) {
      console.error("Error processing files:", error);
      alert("Failed to process files. Please try again.");
    }
  };
  const handleUploadComplete = (data) => {
    console.log(data.files); // Array of formatted file objects
    // Send to server or process as needed
  };
  const createContentStep = () => {
    if (contentId) {
      switch (step) {
        case 0:
          return (
            <ContentForm
              step={step}
              setStep={setStep}
              setImage={setImage}
              setContent={setContent}
              content={content}
              image={image}
              setTitle={setTitle}
              title={title}
              author={author}
              setAuthor={setAuthor}
              topics={topics}
              setTopics={setTopics}
              types={types}
              setTypes={setTypes}
              regions={regions}
              setRegions={setRegions}
              isContentFromUrl={isContentFromUrl}
              imageError={imageError}
              titleError={titleError}
              summaryError={summaryError}
              isEdit={!!contentId}
            />
          );
        case 1:
          return (
            <AddResource
              step={step}
              setStep={setStep}
              files={files}
              setFiles={setFiles}
              handleUploadComplete={handleUploadComplete}
            />
          );
        case 2:
          return (
            <ContentOption
              step={step}
              setStep={setStep}
              shareable={shareable}
              newsLetter={newsLetter}
              featured={featured}
              scheduledDate={scheduledDate}
              setShareable={setShareable}
              setFeatured={setFeatured}
              setNewsLetter={setNewsLetter}
              setScheduledDate={setScheduledDate}
              handleAddContent={handleAddContent}
              endDate={endDate}
              setEndDate={setEndDate}
              isLoadAddContent={isLoadAddContent}
            />
          );

        case 3:
          return (
            <ContentPreview
              step={step}
              setStep={setStep}
              content={content}
              image={image}
              title={title}
              topics={topics}
              scheduledDate={scheduledDate}
              handleAddContent={handleAddContent}
              isLoadAddContent={isLoadAddContent}
            />
          );
        default:
          return <h1>No project match</h1>;
      }
    } else {
      switch (step) {
        case 0:
          return <ContentType step={step} setStep={setStep} />;
        case 1:
          return (
            <ContentURL
              step={step}
              setStep={setStep}
              setIsContentFromUrl={setIsContentFromUrl}
              url={url}
              setURL={setURL}
              SubmitForm={SubmitForm}
              isPending={isPending}
              setImageError={setImageError}
              setTitleError={setTitleError}
              setSummaryError={setSummaryError}
            />
          );
        case 2:
          return (
            <ContentForm
              setStep={setStep}
              step={step}
              setImage={setImage}
              setContent={setContent}
              content={content}
              image={image}
              imageList={imageList}
              setTitle={setTitle}
              title={title}
              author={author}
              setAuthor={setAuthor}
              topics={topics}
              setTopics={setTopics}
              types={types}
              setTypes={setTypes}
              regions={regions}
              setRegions={setRegions}
              isContentFromUrl={isContentFromUrl}
              imageError={imageError}
              titleError={titleError}
              summaryError={summaryError}
            />
          );
        case 3:
          return (
            <AddResource
              step={step}
              setStep={setStep}
              files={files}
              setFiles={setFiles}
              handleUploadComplete={handleUploadComplete}
            />
          );
        case 4:
          return (
            <ContentOption
              step={step}
              setStep={setStep}
              shareable={shareable}
              newsLetter={newsLetter}
              featured={featured}
              scheduledDate={scheduledDate}
              setShareable={setShareable}
              setFeatured={setFeatured}
              setNewsLetter={setNewsLetter}
              setScheduledDate={setScheduledDate}
              handleAddContent={handleAddContent}
              endDate={endDate}
              setEndDate={setEndDate}
              isLoadAddContent={isLoadAddContent}
              publish={publish}
            />
          );
        case 5:
          return (
            <ContentPreview
              step={step}
              setStep={setStep}
              content={content}
              image={image}
              title={title}
              topics={topics}
              scheduledDate={scheduledDate}
              handleAddContent={handleAddContent}
              isLoadAddContent={isLoadAddContent}
              publish={publish}
            />
          );
        default:
          return <h1>No project match</h1>;
      }
    }
  };

  return (
    <section className=" contentContainer">
      <Grid container spacing={2} justifyContent={"center"} height={100}>
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          justifyContent={"center"}
          item
          xs={12}
          md={12}
        >
          <Box m={2}>
            <h1>{contentId ? "Edit" : "Create"} Content</h1>
          </Box>
        </Grid>
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          justifyContent={"center"}
          item
          xs={12}
          md={12}
        >
          <Box className="">{createContentStep()}</Box>
        </Grid>
      </Grid>
    </section>
  );
};

export default AddContentPage;
