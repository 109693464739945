import React from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import CustomButton from "../../common/Button";
import { Info } from "@mui/icons-material";
import { useOrganisation } from "../../../hooks/useOrganisation";
import CreateImage from "../AddContent/ContentForm/CreateContent";

const UploadLogo = ({
  logo,
  handleLogoUpload,
  handleBack,
  handleNext,
  update,
}) => {
  const { primaryColor } = useOrganisation();

  return (
    <Box>
      <Typography variant="h6">
        Upload your logo
        <Tooltip
          title={`Upload an svg OR png logo for a better quality Ideal :
          Width: 300-400 pixel
          Maximum Height: 200 pixels
          Aspect Ratio: Keep between 1:1 to 3:1 (width to height)`}
        >
          <IconButton>
            <Info />
          </IconButton>
        </Tooltip>
      </Typography>
      <Grid item>
        <CreateImage
          image={logo}
          setImage={handleLogoUpload}
          isLogo={true}
          update={update}
        />
      </Grid>
      <Box sx={{ marginTop: 4 }} display={"flex"} justifyContent={"end"}>
        <CustomButton
          onClick={handleBack}
          sx={{
            marginRight: 2,
          }}
          backgroundColor="white"
          text="Back"
        />

        <CustomButton onClick={handleNext} text="Next" />
      </Box>
    </Box>
  );
};

export default UploadLogo;
