import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { Box, Grid, Paper, Typography } from "@mui/material";
import DataCard from "../DataCard";
import Loading from "../../../common/Loading";
import Button from "../../../common/Button";
import Table from "../../../common/Table";
import { columns } from "./columns";
import CustomPieChart from "./CustomPieChart";
import { exportToCSV } from "../../../../helpers";
import {
  getAllCreatedContentStatistics,
  getAllSharedContentStatistics,
  getAllViewedContentStatistics,
  getContentStatistics,
} from "../../../../store/api/statistics";
import { useOrganisation } from "../../../../hooks/useOrganisation";

const ContentTab = ({ tabIndex, index, startDate, endDate, organisation }) => {
  const { primaryColor } = useOrganisation();

  const cardsData = [
    {
      title: "Content Created",
      key: "created",
      countKey: "created",
      getDataFunction: getAllCreatedContentStatistics,
    },
    {
      title: "Content Views",
      key: "views",
      countKey: "views",
      getDataFunction: getAllViewedContentStatistics,
    },
    {
      title: "Content Shares",
      key: "shared",
      countKey: "shares",
      getDataFunction: getAllSharedContentStatistics,
    },
    {
      title: "Avg Read Time",
      key: "readTime",
      countKey: "avgReadTime",
      suffix: " sec",
    },
  ];

  const [selectedCard, setSelectedCard] = useState({
    key: "created",
    getDataFunction: getAllCreatedContentStatistics,
  });

  const { data: statistics, isLoading } = useQuery({
    queryKey: ["contentStatistics", startDate, endDate, tabIndex, organisation],
    queryFn: () =>
      getContentStatistics({
        startDate,
        endDate,
        organisationId: organisation,
      }),
  });

  const { data: contentStatistics, isLoading: loadContent } = useQuery({
    queryKey: [selectedCard?.key, startDate, endDate],
    queryFn: () =>
      selectedCard.getDataFunction({ startDate, endDate, pageNumber: 1 }),
    enabled: !!selectedCard?.getDataFunction,
  });

  const handleCardClick = (cardData) => {
    if (cardData.getDataFunction) {
      setSelectedCard({
        key: cardData.key,
        getDataFunction: cardData.getDataFunction,
      });
    }
  };

  const chartOptions = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: true,
          color: "#f0f0f0",
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const mostViewedByTopicData = {
    labels: statistics?.mvByTopic?.map((el) => el.name) || [],
    datasets: [
      {
        data: statistics?.mvByTopic?.map((el) => el.count) || [],
        backgroundColor: "#A78BFA",
        borderRadius: 4,
        barThickness: 20,
      },
    ],
  };

  const cloudSecurityData = {
    labels: statistics?.mvByType?.map((el) => el.name) || [],
    datasets: [
      {
        data: statistics?.mvByType?.map((el) => el.count) || [],
        backgroundColor: primaryColor,
        borderRadius: 4,
        barThickness: 20,
      },
    ],
  };

  if (isLoading && !statistics) {
    return <Loading />;
  }

  return (
    <Box sx={{ maxWidth: "1200px", mx: "auto", px: { xs: 2, sm: 3, md: 4 } }}>
      <Grid container spacing={2} alignItems="stretch" justifyContent="center">
        {cardsData.map((cardData) => (
          <Grid item xs={12} sm={6} md={3} key={cardData.key}>
            <DataCard
              title={cardData.title}
              count={
                cardData.suffix
                  ? `${statistics?.[cardData.countKey] || 0}${cardData.suffix}`
                  : statistics?.[cardData.countKey] || 0
              }
              onClick={() => handleCardClick(cardData)}
              isSelected={selectedCard.key === cardData.key}
              backgroundColor="#F3F4F6"
            />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }} justifyContent="center">
        <Grid item xs={12} md={4}>
          <CustomPieChart
            title="Most Viewed Articles"
            data={statistics?.mvArticles}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: "100%",
              minHeight: "300px",
              "& h6": {
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "#374151",
                mb: 2,
              },
            }}
          >
            <Typography variant="h6">Most Viewed by Topic</Typography>
            <div style={{ height: "250px" }}>
              <Bar data={mostViewedByTopicData} options={chartOptions} />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: "100%",
              minHeight: "300px",
              "& h6": {
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "#374151",
                mb: 2,
              },
            }}
          >
            <Typography variant="h6">Most Viewed by Type</Typography>
            <div style={{ height: "250px" }}>
              <Bar data={cloudSecurityData} options={chartOptions} />
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button
          text="Export CSV"
          onClick={() => exportToCSV(contentStatistics?.paginatedContent)}
        />
      </Box>

      {loadContent && selectedCard ? (
        <Loading />
      ) : (
        <Box sx={{ overflowX: "auto", width: "100%" }}>
          <Table
            data={contentStatistics?.paginatedContent}
            columns={columns({})}
          />
        </Box>
      )}
    </Box>
  );
};

export default ContentTab;
