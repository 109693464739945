import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import Button from "../../common/Button";

const Topics = ({ topics, setTopics, handleBack, handleSave }) => {
  const filter = createFilterOptions();

  const handleTopicChange = (event, newValues) => {
    const processedTopics = newValues.map((value) => {
      if (typeof value === "string") {
        return value;
      } else if (value.inputValue) {
        return value.inputValue;
      } else {
        return value.title;
      }
    });
    const uniqueTopics = Array.from(new Set(processedTopics));
    setTopics(uniqueTopics);
  };

  return (
    <Box>
      {/* Explanatory note */}
      <Typography variant="h6" gutterBottom>
        Add Your Topics
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        To start adding your categories, please type them in the box and click
        "save".
      </Typography>

      <Autocomplete
        multiple
        freeSolo
        options={[]} // Start with no options
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          if (inputValue !== "") {
            filtered.push({
              inputValue,
              title: `Click to Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.title
        }
        isOptionEqualToValue={(option, value) =>
          option.title === (typeof value === "string" ? value : value.title)
        }
        value={topics.map((topic) => ({ title: topic }))}
        onChange={handleTopicChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add Topics"
            placeholder="Type a topic"
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option.title}
              label={option.title}
              {...getTagProps({ index })}
            />
          ))
        }
      />

      <Box sx={{ marginTop: 4 }} display="flex" justifyContent="end">
        <Button
          onClick={handleBack}
          sx={{
            marginRight: 2,
          }}
          backgroundColor="white"
          text="Back"
        />
        <Button onClick={handleSave} text="Save" />
      </Box>
    </Box>
  );
};

export default Topics;
