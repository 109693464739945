import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getUserPrefrences,
  updateUserPrefrences,
} from "../../../../store/api/users";
import Toast from "../../../common/Toast";
import Button from "../../../common/Button";
import CustomSwitch from "../../../common/Swicth";
import { useOrganisation } from "../../../../hooks/useOrganisation";

interface Preferences {
  id: string;
  title: string;
  isSelected: boolean;
}

interface getUserPreferencesResponse {
  regions: Preferences[];
  topics: Preferences[];
  types: Preferences[];
  isDefaultView: boolean;
  browserAlert: boolean;
  newsLetter: boolean;
  notifications: Preferences[];
}

const PreferencesCenterMerged = () => {
  const queryClient = useQueryClient();
  const { primaryColor } = useOrganisation();

  // Preferences state
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [defaultView, setDefaultView] = useState(false);
  const [emailFrequency, setEmailFrequency] = useState("0");
  const [pushEnabled, setPushEnabled] = useState(false);
  const [browserAlert, setBrowserAlert] = useState(false);

  const [responseMessage, setResponseMessage] = useState("");
  const [status, setStatus] = useState(200);
  const [toastKey, setToastKey] = useState(0);

  const { data: preferencesData, isLoading: preferencesLoading } = useQuery({
    queryKey: ["userPrefrences"],
    queryFn: getUserPrefrences,
  });

  const showToast = (message: string, statusCode: number) => {
    setResponseMessage(message);
    setStatus(statusCode);
    setToastKey((prev) => prev + 1);
  };

  const validateSelections = () => {
    if (!defaultView) return true;

    if (selectedTopics.length === 0) {
      showToast("Please select at least one topic for default view", 400);
      return false;
    }

    if (selectedTypes.length === 0) {
      showToast("Please select at least one type for default view", 400);
      return false;
    }

    if (selectedRegions.length === 0) {
      showToast("Please select at least one region for default view", 400);
      return false;
    }

    return true;
  };

  const { mutate: updatePreferences, isPending: isPreferencesUpdating } =
    useMutation({
      mutationFn: updateUserPrefrences,
      onSuccess: async () => {
        queryClient.refetchQueries({
          queryKey: ["userPrefrences"],
        });
        showToast("Preferences Updated", 200);
      },
      onError: (error: any) => {
        console.log(error);
        showToast("Failed to update preferences", 400);
      },
    });

  useEffect(() => {
    if (!preferencesLoading && preferencesData) {
      const initialSelectedTopics = preferencesData?.topics
        .filter((topic) => topic.isSelected)
        .map((topic) => topic.id);
      const initialSelectedTypes = preferencesData?.types
        .filter((region) => region.isSelected)
        .map((region) => region.id);
      const initialSelectedRegions = preferencesData?.regions
        .filter((region) => region.isSelected)
        .map((region) => region.id);

      setSelectedTopics(initialSelectedTopics || []);
      setSelectedTypes(initialSelectedTypes || []);
      setSelectedRegions(initialSelectedRegions || []);
      setDefaultView(preferencesData?.isDefaultView);
      setEmailFrequency(
        preferencesData?.notifications?.find((option) => option?.isSelected)
          ?.id || "0"
      );
      setPushEnabled(preferencesData?.newsLetter || false);
      setBrowserAlert(preferencesData?.browserAlert || false);
    }
  }, [preferencesLoading, preferencesData]);

  // Handlers for types, topics, regions
  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedTypes((prev) => [...prev, value]);
    } else {
      setSelectedTypes((prev) => prev.filter((type) => type !== value));
    }
  };

  const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedRegions((prev) => [...prev, value]);
    } else {
      setSelectedRegions((prev) => prev.filter((region) => region !== value));
    }
  };

  const handleTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedTopics((prev) => [...prev, value]);
    } else {
      setSelectedTopics((prev) => prev.filter((topic) => topic !== value));
    }
  };

  const handleSelectAllTypes = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && preferencesData?.types) {
      const allTypesIds = preferencesData.types.map((type) => type.id);
      setSelectedTypes(allTypesIds);
    } else {
      setSelectedTypes([]);
    }
  };

  const handleSelectAllTopics = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked && preferencesData?.topics) {
      const allTopicIds = preferencesData.topics.map((topic) => topic.id);
      setSelectedTopics(allTopicIds);
    } else {
      setSelectedTopics([]);
    }
  };

  const handleSelectAllRegions = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked && preferencesData?.regions) {
      const allRegionIds = preferencesData.regions.map((region) => region.id);
      setSelectedRegions(allRegionIds);
    } else {
      setSelectedRegions([]);
    }
  };

  const handlePushToggle = () => {
    if (!browserAlert) {
      Notification.requestPermission().then((permission) => {
        console.log(permission);
        if (permission === "granted") {
          setBrowserAlert(true);
        } else {
          setBrowserAlert(false);
        }
      });
    } else {
      setBrowserAlert(false);
    }
  };

  const handleSave = () => {
    if (!validateSelections()) return;

    updatePreferences({
      userTopicIds: selectedTopics,
      userTypeIds: selectedTypes,
      userRegionIds: selectedRegions,
      IsDefaultView: defaultView,
      browserAlert: browserAlert,
      newsLetter: pushEnabled,
      emailNotification: +emailFrequency,
    });
  };

  return (
    <section>
      <Box p={4} mx="auto">
        <Grid container spacing={4}>
          {/* Preferences Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Your Preferences
            </Typography>

            {/* Types Section */}
            <Grid item xs={12} style={{ marginTop: 12 }}>
              <Typography variant="body1" gutterBottom>
                Which Types are you interested in?
              </Typography>
              {preferencesData?.types.map((type) => (
                <FormControlLabel
                  key={type.id}
                  control={
                    <Checkbox
                      value={type.id}
                      checked={selectedTypes.includes(type.id)}
                      onChange={handleTypeChange}
                      sx={{
                        color: primaryColor,
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={type.title}
                />
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      selectedTypes.length === preferencesData?.types.length
                    }
                    onChange={handleSelectAllTypes}
                    sx={{
                      color: primaryColor,
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label="Check all"
              />
            </Grid>

            {/* Topics Section */}
            <Grid item xs={12} style={{ marginTop: 12 }}>
              <Typography variant="body1" gutterBottom>
                Which Topics are you interested in?
              </Typography>
              {preferencesData?.topics.map((topic) => (
                <FormControlLabel
                  key={topic.id}
                  control={
                    <Checkbox
                      value={topic.id}
                      checked={selectedTopics.includes(topic.id)}
                      onChange={handleTopicChange}
                      sx={{
                        color: primaryColor,
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={topic.title}
                />
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      selectedTopics.length === preferencesData?.topics.length
                    }
                    onChange={handleSelectAllTopics}
                    sx={{
                      color: primaryColor,
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label="Check all"
              />
            </Grid>

            {/* Regions Section */}
            <Grid item xs={12} style={{ marginTop: 12 }}>
              <Typography variant="body1" gutterBottom>
                Which Regions are you interested in?
              </Typography>
              {preferencesData?.regions.map((region) => (
                <FormControlLabel
                  key={region.id}
                  control={
                    <Checkbox
                      value={region.id}
                      checked={selectedRegions.includes(region.id)}
                      onChange={handleRegionChange}
                      sx={{
                        color: primaryColor,
                        "&.Mui-checked": {
                          color: primaryColor,
                        },
                      }}
                    />
                  }
                  label={region.title}
                />
              ))}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      selectedRegions.length === preferencesData?.regions.length
                    }
                    onChange={handleSelectAllRegions}
                    sx={{
                      color: primaryColor,
                      "&.Mui-checked": {
                        color: primaryColor,
                      },
                    }}
                  />
                }
                label="Check all"
              />
            </Grid>
            <hr color="lightgray" style={{ marginTop: 8 }} />

            {/* Default View */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={defaultView}
                  onChange={(e) => setDefaultView(e.target.checked)}
                  sx={{
                    color: primaryColor,
                    "&.Mui-checked": {
                      color: primaryColor,
                    },
                  }}
                />
              }
              label="Make this my default view"
              sx={{ mt: 2 }}
            />
          </Grid>

          {/* Notifications Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Notification Settings
            </Typography>
            <Grid container spacing={2}>
              {/* Email Frequency */}
              <Grid item xs={12} style={{ marginTop: 12 }}>
                <Typography variant="body1" gutterBottom>
                  By Email
                </Typography>
                <Select
                  fullWidth
                  value={emailFrequency}
                  onChange={(e) => setEmailFrequency(e.target.value)}
                >
                  {preferencesData?.notifications?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              {/* Browser Alert */}
              <Grid item xs={12} style={{ marginTop: 12 }}>
                <Typography variant="body1" gutterBottom>
                  Browser Alert
                </Typography>
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={browserAlert}
                      onChange={handlePushToggle}
                      name="primary"
                      primaryColor={primaryColor || "#96f7dc"}
                    />
                  }
                  label={
                    <>
                      Enable push messaging on this device{" "}
                      <Tooltip title="Push messaging allows you to receive alerts directly in your browser.">
                        <IconButton size="small">
                          <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                  labelPlacement="start"
                />
              </Grid>

              {/* Newsletter */}
              <Grid item xs={12} style={{ marginTop: 12 }}>
                <Typography variant="body1" gutterBottom>
                  Weekly Newsletter
                </Typography>
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={pushEnabled}
                      onChange={() => setPushEnabled(!pushEnabled)}
                      name="time"
                      primaryColor={primaryColor || "#96f7dc"}
                    />
                  }
                  label={
                    <>
                      Receive a weekly curated newsletter{" "}
                      <Tooltip title="Receive an email newsletter with curated content every week.">
                        <IconButton size="small">
                          <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Save Buttons */}
        <Box mt={4} display="flex" justifyContent="end">
          <Button text="Back" backgroundColor="white" />
          <Button text="Save" onClick={handleSave} />
        </Box>
      </Box>

      <Toast key={toastKey} message={responseMessage} status={status} />
    </section>
  );
};

export default PreferencesCenterMerged;
