import React, { useState } from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { useDropzone } from "react-dropzone";
import Modal from "react-modal";
import Button from "../../../common/Button";
import {
  convertBlobToBase64,
  convertImageUrlToBase64,
  downloadImage,
} from "../../../../helpers/validator";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { useQuery } from "@tanstack/react-query";
import { getAllResources } from "../../../../store/api/resources";
import ImageResourcesModal from "./ImageList";

const CreateImage = ({ setImage, image, imageList, update, isContent }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalResourcesOpen, setIsModalResourcesOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const { data: imagesResources, isLoading } = useQuery({
    queryKey: ["imageresourcesList"],
    queryFn: () => getAllResources({ IsOnlyImage: true }),
  });

  const maxWidth = 400;
  const maxHeight = 200;

  const resizeImage = (file, callback) => {
    if (file.type === "image/svg+xml") {
      // If the file is an SVG, skip resizing and pass it directly
      callback(file);
      return;
    }

    // Resize logic for raster images (PNG, JPEG, etc.)
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;
    };

    img.onload = () => {
      const canvas = document.createElement("canvas");
      let { width, height } = img;

      // Resize logic
      if (width > maxWidth || height > maxHeight) {
        const aspectRatio = width / height;

        if (aspectRatio > 1) {
          // Landscape
          width = maxWidth;
          height = Math.min(maxHeight, width / aspectRatio);
        } else {
          // Portrait or square
          height = maxHeight;
          width = Math.min(maxWidth, height * aspectRatio);
        }
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          const resizedFile = new File([blob], file.name, { type: file.type });
          callback(resizedFile);
        },
        file.type,
        1
      );
    };

    reader.readAsDataURL(file);
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      resizeImage(file, (resizedFile) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage({
            fileName: resizedFile.name,
            contentType: resizedFile.type,
            fileData: reader.result.split(",")[1],
          });
        };
        reader.readAsDataURL(resizedFile);
      });
    }
  };

  const handleSelected = async (image) => {
    try {
      // Attempt to convert the image URL to Base64
      const file = await convertImageUrlToBase64(image);
      setImage({
        ...file,
      });
    } catch (error) {
      console.error("Failed to convert image to Base64:", error);
      // If Base64 conversion fails, trigger auto-download
      downloadImage(image);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64 = e.target.result.split(",")[1]; // Get the Base64 data
        setImage({
          fileName: file.name,
          contentType: file.type,
          fileData: base64,
        });
      };
      reader.readAsDataURL(file); // Convert the file to Base64
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid
        item
        container
        mt={1}
        xs={12}
        md={12}
        spacing={2}
        justifyContent={"flex-end"}
      >
        {isContent && (
          <Button
            text="Add Image"
            onClick={() => setIsModalResourcesOpen(true)}
          />
        )}
        <Button text="Clear Image" onClick={() => setImage("")} />
      </Grid>

      <Grid
        item
        container
        xs={12}
        md={12}
        spacing={2}
        alignItems={"center"}
        sx={{
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Grid item md={6}>
          {update && !image?.contentType ? (
            <Box
              sx={{
                height: "auto",
                width: "200px",
                margin: "0 auto",
              }}
            >
              <img
                src={`${image}?${"sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D"}`}
                alt="Preview"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          ) : image?.url ? (
            <Box
              sx={{
                height: "auto",
                width: "200px",
                margin: "0 auto",
              }}
            >
              <img
                src={`${
                  image?.url
                }?${"sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D"}`}
                alt="Preview"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          ) : image || imageList?.length ? (
            <Grid
              item
              container
              xs={12}
              md={12}
              spacing={2}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Box
                sx={{
                  height: "auto",
                  width: "200px",
                  margin: "0 auto",
                }}
              >
                {image?.contentType === "image/svg+xml" ? (
                  <img
                    src={`data:image/svg+xml;base64,${image?.fileData}`}
                    alt="Preview"
                    style={{
                      width: "200px",
                      height: "auto",
                    }}
                  />
                ) : (
                  <img
                    src={`data:${image?.contentType};base64,${image?.fileData}`}
                    alt="Preview"
                    style={{
                      width: "200px",
                      height: "auto",
                    }}
                  />
                )}
                {imageList?.length > 0 && (
                  <Grid
                    item
                    container
                    xs={12}
                    md={12}
                    mt={2}
                    spacing={2}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      {imageList.map((img, i) => (
                        <Box
                          key={i}
                          sx={{
                            position: "relative",
                            margin: "4px",
                            width: "30%",
                            border:
                              img.split("/").pop() === image?.fileName
                                ? "black 2px solid"
                                : "",
                          }}
                        >
                          <img
                            width={"100%"}
                            style={{
                              cursor: "pointer",
                              marginTop: "4px",
                              borderRadius: "4px",
                            }}
                            src={img}
                            alt={`article ${i}`}
                            onClick={() => handleSelected(img)}
                          />
                          <IconButton
                            onClick={() => openModal(img)}
                            sx={{
                              position: "absolute",
                              padding: 1,
                              top: 0,
                              right: 0,
                              color: "#fff",
                            }}
                          >
                            <ZoomInIcon />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Grid>
          ) : (
            <Box
              sx={{
                border: "1px dashed grey",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "150px",
                cursor: "pointer",
                width: "100%",
                maxWidth: "300px",
                margin: "0 auto",
              }}
            >
              <Typography>Preview</Typography>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            {...getRootProps()}
            sx={{
              width: "100%",
              height: "150px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              border: "1px dashed black",
              backgroundColor: isDragActive ? "#f0f0f0" : "transparent",
              maxWidth: "300px",
              margin: "0 auto",
            }}
          >
            <input {...getInputProps()} />
            <Typography>
              {isDragActive
                ? "Drop the image here..."
                : "Drag and drop or click to browse your desktop"}
            </Typography>
            <Typography variant="caption">
              File types: .jpg, .gif, .png, or .svg
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90%",
            maxHeight: "90%",
            padding: 0,
            border: "none",
            backgroundColor: "transparent",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            textAlign: "center",
          }}
        >
          <img
            src={selectedImage}
            alt="Zoomed"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
          <IconButton
            onClick={closeModal}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "#fff",
            }}
          >
            ✖
          </IconButton>
        </Box>
      </Modal>
      <ImageResourcesModal
        open={isModalResourcesOpen}
        images={imagesResources?.paginatedContent}
        onClose={() => setIsModalResourcesOpen(false)}
        onImageSelect={setImage}
      />
    </Box>
  );
};

export default CreateImage;
