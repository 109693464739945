import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

interface CustomSwitchProps {
  primaryColor: string;
}

export default styled(Switch, {
  shouldForwardProp: (prop) => prop !== "primaryColor",
})<CustomSwitchProps>(({ theme, checked, primaryColor }) => ({
  "& .MuiSwitch-switchBase": {
    color: checked ? "green" : theme.palette.error.main,
  },
  "& .MuiSwitch-track": {
    backgroundColor: checked ? "gray" : "gray",
  },
  ".Mui-checked+.MuiSwitch-track": {
    backgroundColor: checked ? "gray" : "gray",
  },
}));
