import { Box, Checkbox, MenuItem, Select, Typography } from "@mui/material";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { SwapVert as SwapVertIcon } from "@mui/icons-material";
import "./style.css";
import { roles } from "../../../constants/routes";
import Button from "../../common/Button";

export const columns = ({
  selectedIds,
  handleSelect,
  handleSelectAll,
  handleStatusChange,
  setSortBy,
  setIsDescending,
  isDescending,
  setOpenView,
  setContentPreview,
  approved,
  schedule,
  setStatusContent,
  statusContent,
  primaryColor,
  setCurrentPage,
  role,
}) => [
  {
    accessorKey: "article",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          indeterminate={table.getIsSomeRowsSelected()}
          checked={table.getIsSomeRowsSelected().length === 10}
          onChange={() => handleSelectAll()}
          style={{ color: "white" }}
        />

        <div
          onClick={() => {
            setSortBy("Article");
            setIsDescending(!isDescending);
          }}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="body2">Article</Typography>
          <SwapVertIcon onClick={() => {}} style={{ cursor: "pointer" }} />
        </div>
      </div>
    ),
    cell: ({ row, value }) => (
      <>
        <Checkbox
          checked={selectedIds.includes(row.original.id)}
          onChange={() => handleSelect(row.original.id)}
        />
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.article}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "topic",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("Topic");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Topic</Typography>
        <SwapVertIcon onClick={() => {}} style={{ cursor: "pointer" }} />
      </div>
    ),
  },
  {
    accessorKey: "author",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("Author");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Author</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
  },
  {
    accessorKey: "dateCreated",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("DateCreated");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Date Created</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {dayjs(row.original.dateCreated).format("DD/MM/YYYY")}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "scheduledDate",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("scheduledDate");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">schedule Date</Typography>
        <SwapVertIcon style={{ cursor: "pointer" }} />
      </div>
    ),
    cell: ({ row }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {dayjs(row.original.scheduledDate).format("DD/MM/YYYY")}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "status",

    header: ({ table }) => {
      const statusOptions = [
        { label: "All", value: null },
        { label: "Pending", value: 0 },
        { label: "Approved", value: 1 },
        { label: "Rejected", value: 3 },
        { label: "Archived", value: 4 },
      ];

      const handleFilterChange = (event) => {
        const selectedStatus = event.target.value;
        setStatusContent(selectedStatus);
        setCurrentPage(1);
      };

      return approved || schedule ? (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            setSortBy("DateCreated");
            setIsDescending(!isDescending);
          }}
        >
          <Typography variant="body2">Status</Typography>
          <SwapVertIcon style={{ cursor: "pointer" }} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Select
            value={statusContent}
            onChange={handleFilterChange}
            sx={{
              minWidth: 120,
              backgroundColor: "white",
              borderRadius: "24px",
              fontSize: "0.9rem",
            }}
            placeholder="Choose a state"
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label ? option.label : "All"}
              </MenuItem>
            ))}
          </Select>
        </div>
      );
    },
    cell: ({ row }) => (
      <>
        {roles.ContentCreator === role ? (
          <>
            <Typography>{row.original.status}</Typography>
          </>
        ) : (
          <Select
            value={row.original.status}
            onChange={(event) =>
              handleStatusChange(row.original.id, event.target.value)
            }
            sx={{ minWidth: 120 }}
            style={{
              border: "2px solid",
              padding: 2,
              borderColor:
                row.original.status === "Pending"
                  ? "orange"
                  : row.original.status === "Approved"
                  ? primaryColor
                  : "red",
              borderRadius: "24px",
            }}
          >
            {row.original.status === "Pending" && (
              <MenuItem
                style={{ backgroundColor: "none", padding: 4 }}
                value="Pending"
              >
                Pending
              </MenuItem>
            )}
            <MenuItem
              style={{ backgroundColor: "none", padding: 4 }}
              value="Approved"
            >
              Approved
            </MenuItem>
            <MenuItem
              style={{ backgroundColor: "none", padding: 4 }}
              value="Rejected"
            >
              Rejected
            </MenuItem>
            {row.original.status === "Archived" && (
              <MenuItem
                style={{ backgroundColor: "none", padding: 4 }}
                value="Archived"
              >
                Archived
              </MenuItem>
            )}
          </Select>
        )}
      </>
    ),
  },
  {
    id: "view",
    header: "",
    cell: ({ row }) => (
      <Button
        onClick={() => {
          setOpenView(true);
          setContentPreview(row.original);
        }}
        endIcon={
          <VisibilityIcon fontSize="small" style={{ marginRight: "4px" }} />
        }
        text={"View"}
        backgroundColor="transparent"
        style={{
          border: "none",
        }}
      />
    ),
  },
];
