import React, { useState, useCallback } from "react";
import { Box, Grid, Card } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Bar } from "react-chartjs-2";

import Table from "../../../common/Table";
import Loading from "../../../common/Loading";
import Button from "../../../common/Button";
import DataCard from "../DataCard";

import { columns } from "./columns";
import { exportToCSV } from "../../../../helpers";
import {
  getAllActiveUserStatistics,
  getAllBouncesUserStatistics,
  getAllLoginUserStatistics,
  getAllSubscriptionUserStatistics,
  getAllUnActiveUserStatistics,
  getAllUnSubscriptionUserStatistics,
  getUserStatistics,
} from "../../../../store/api/statistics";

const TabPanel = ({ value, index, children }) => {
  return value === index && <Box>{children}</Box>;
};

const UserTab = ({ tabIndex, index, startDate, endDate, organisation }) => {
  const [selectedCard, setSelectedCard] = useState({
    key: "active",
    getDataFunction: getAllActiveUserStatistics,
  });

  // Define cards data with all necessary information
  const cardsData = [
    {
      title: "Subscribers",
      key: "subscribers",
      countKey: "subscribers",
      getDataFunction: getAllSubscriptionUserStatistics,
    },
    {
      title: "Logins",
      key: "logins",
      countKey: "logins",
      getDataFunction: getAllLoginUserStatistics,
    },
    {
      title: "Active Users",
      key: "active",
      countKey: "active",
      getDataFunction: getAllActiveUserStatistics,
    },
    {
      title: "Inactive Users",
      key: "unactive",
      countKey: "inactive",
      getDataFunction: getAllUnActiveUserStatistics,
    },
    {
      title: "Unsubscribes",
      key: "unsubscribers",
      countKey: "unsubscribers",
      getDataFunction: getAllUnSubscriptionUserStatistics,
    },
    {
      title: "Bounces",
      key: "bounces",
      countKey: "bounces",
      getDataFunction: getAllBouncesUserStatistics,
    },
  ];

  // Query for overall user statistics
  const { data: statistics } = useQuery({
    queryKey: ["userStatistics", startDate, endDate],
    queryFn: () =>
      getUserStatistics({
        startDate,
        endDate,
        pageNumber: 1,
      }),
  });

  // Fetch data based on the selected card
  const { data: usersStatistics, isLoading: loadUsers } = useQuery({
    queryKey: [selectedCard?.key, startDate, endDate],
    queryFn: () =>
      selectedCard.getDataFunction({ startDate, endDate, pageNumber: 1 }),
    enabled: !!selectedCard,
  });

  const handleUserList = useCallback((cardData) => {
    setSelectedCard({
      key: cardData.key,
      getDataFunction: cardData.getDataFunction,
    });
  }, []);

  // Chart data and options...
  const data = {
    labels: statistics?.engagements?.map((el) => el.name) || [],
    datasets: [
      {
        label: "Engagement",
        data: statistics?.engagements?.map((el) => el.count) || [],
        borderWidth: 1,
        backgroundColor: "grey",
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: { beginAtZero: true },
      x: {
        categoryPercentage: 0.6,
        barPercentage: 0.5,
      },
    },
    responsive: true,
  };

  return (
    <TabPanel value={tabIndex} index={index}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <Bar data={data} options={chartOptions} />
          </Card>
        </Grid>

        {/* Data Cards */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            {cardsData.map((cardData) => (
              <Grid item xs={6} md={4} key={cardData.key}>
                <DataCard
                  title={cardData.title}
                  count={statistics?.[cardData.countKey] || 0}
                  onClick={() => handleUserList(cardData)}
                  isSelected={selectedCard.key === cardData.key}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {/* Table and Export Button */}
      <Grid
        style={{ marginTop: "1em", display: "flex", justifyContent: "end" }}
      >
        <Button
          text="Export CSV"
          onClick={() => exportToCSV(usersStatistics?.paginatedContent)}
        />
      </Grid>
      {loadUsers && selectedCard ? (
        <Loading />
      ) : (
        <Table data={usersStatistics?.paginatedContent} columns={columns({})} />
      )}
    </TabPanel>
  );
};

export default UserTab;
