import React, { useRef, useState, useEffect } from "react";
import { IconButton, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import ArticleCard from "../../../common/ArticleCard";
import { useOrganisation } from "../../../../hooks/useOrganisation";
import { getTextColor } from "../../../../helpers";

const FeaturedContent = ({ articles }) => {
  const scrollContainerRef = useRef(null);
  const { primaryColor } = useOrganisation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);

  useEffect(() => {
    if (scrollContainerRef.current) {
      setMaxScroll(
        scrollContainerRef.current.scrollWidth -
          scrollContainerRef.current.clientWidth
      );
    }
  }, [articles]);

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const containerWidth = scrollContainerRef.current.clientWidth;
      const scrollAmount = direction === "left" ? -300 : 300;
      let newPosition = scrollPosition + scrollAmount;

      // Implement infinite scroll effect
      if (newPosition < 0) {
        // If scrolling past the start, jump to end
        newPosition = maxScroll;
        scrollContainerRef.current.scrollLeft = maxScroll;
      } else if (newPosition > maxScroll) {
        // If scrolling past the end, jump to start
        newPosition = 0;
        scrollContainerRef.current.scrollLeft = 0;
      } else {
        // Normal smooth scroll
        scrollContainerRef.current.scrollBy({
          left: scrollAmount,
          behavior: "smooth",
        });
      }

      setScrollPosition(newPosition);
    }
  };

  const handleNativeScroll = () => {
    if (scrollContainerRef.current) {
      const newPosition = scrollContainerRef.current.scrollLeft;
      setScrollPosition(newPosition);
    }
  };

  return (
    <section style={{ marginRight: 0, marginTop: 24 }}>
      <div
        style={{
          backgroundColor: "#000",
          padding: "24px",
          position: "relative",
        }}
      >
        <Typography
          variant="h5"
          style={{ color: primaryColor, fontWeight: "bold" }}
        >
          Featured Content
        </Typography>

        <IconButton
          onClick={() => handleScroll("left")}
          style={{
            position: "absolute",
            top: "50%",
            left: "0",
            transform: "translateY(-50%)",
            zIndex: 1,
            color: getTextColor(primaryColor) || "black",
            backgroundColor: primaryColor,
            borderRadius: "50%",
          }}
        >
          <ChevronLeft style={{ width: 30, height: 30 }} />
        </IconButton>

        <div
          ref={scrollContainerRef}
          onScroll={handleNativeScroll}
          style={{
            overflowX: "auto",
            display: "flex",
            gap: "16px",
            padding: "20px",
            scrollBehavior: "smooth",
            msOverflowStyle: "none", // Hide scrollbar for IE/Edge
            scrollbarWidth: "none", // Hide scrollbar for Firefox
            "&::-webkit-scrollbar": {
              // Hide scrollbar for Chrome/Safari
              display: "none",
            },
          }}
        >
          {articles.slice(0, 8).map((article, index) => (
            <ArticleCard
              article={article}
              key={index}
              width={280}
              featured={true}
            />
          ))}
        </div>

        <IconButton
          onClick={() => handleScroll("right")}
          style={{
            position: "absolute",
            top: "50%",
            right: "0",
            transform: "translateY(-50%)",
            zIndex: 1,
            color: getTextColor(primaryColor) || "black",
            backgroundColor: primaryColor,
            borderRadius: "50%",
          }}
        >
          <ChevronRight style={{ width: 30, height: 30 }} />
        </IconButton>
      </div>
    </section>
  );
};

export default FeaturedContent;
