import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const FileViewerModal = ({ open, onClose, fileData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const SAS_TOKEN =
    "sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D";

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      setError(false);
    }
  }, [open]);

  const getFileType = (url) => {
    if (!url) return "";
    return url.split(".").pop()?.toLowerCase() || "";
  };
  useEffect(() => {
    setIsLoading(false);
  }, []);

  const getSecureUrl = (url) => `${url}?${SAS_TOKEN}`;

  const getContent = () => {
    const fileType = getFileType(fileData?.fileUrl);
    const secureUrl = getSecureUrl(fileData?.fileUrl);

    if (["jpg", "jpeg", "png", "gif"].includes(fileType)) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "400px",
          }}
        >
          {isLoading && <CircularProgress />}
          <img
            src={secureUrl}
            alt={fileData.title}
            style={{
              maxWidth: "100%",
              maxHeight: "70vh",
              display: isLoading ? "none" : "block",
            }}
            onLoad={() => setIsLoading(false)}
            onError={() => {
              setIsLoading(false);
              setError(true);
            }}
          />
        </Box>
      );
    }

    if (["mp4", "mov"].includes(fileType)) {
      return (
        <Box
          sx={{ display: "flex", justifyContent: "center", minHeight: "400px" }}
        >
          {isLoading && <CircularProgress />}
          <video
            controls
            style={{
              maxWidth: "100%",
              maxHeight: "70vh",
              display: isLoading ? "none" : "block",
            }}
            onLoadedData={() => setIsLoading(false)}
            onError={() => {
              setIsLoading(false);
              setError(true);
            }}
          >
            <source src={secureUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "400px",
          gap: 2,
        }}
      >
        <Typography variant="h6" color="text.secondary">
          Preview not available
        </Typography>
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          onClick={() => window.open(secureUrl, "_blank")}
        >
          Download File
        </Button>
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="div" sx={{ pr: 4 }}>
            {fileData?.title}
          </Typography>
          <Box>
            <IconButton
              onClick={() =>
                window.open(getSecureUrl(fileData?.fileUrl), "_blank")
              }
              sx={{ mr: 1 }}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        {error ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "400px",
              gap: 2,
            }}
          >
            <ErrorOutlineIcon sx={{ fontSize: 48, color: "error.main" }} />
            <Typography color="error">
              Error loading file. Please try downloading instead.
            </Typography>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={() =>
                window.open(getSecureUrl(fileData?.fileUrl), "_blank")
              }
            >
              Download File
            </Button>
          </Box>
        ) : (
          getContent()
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileViewerModal;
