import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Toast from "../../common/Toast";
import Button from "../../common/Button";
import { getUserDetails, updateUserDetails } from "../../../store/api/users";
import { getTimezoneOnboardedOrganisation } from "../../../store/api/organisation";

// Define interfaces for our data types
interface Country {
  id: string;
  title: string;
  isSelected: boolean;
}

interface Region {
  id: string;
  title: string;
  isSelected: boolean;
}

interface Timezone {
  id: string;
  title: string;
  isSelected: boolean;
}

interface UserDetails {
  fullName: string;
  email: string;
  countries: Country[];
  regions: Region[];
  timezones: Timezone[];
  companyType: string;
  jobTitle: string;
}

interface ErrorState {
  fullName?: string;
  email?: string;
  country?: string;
  region?: string;
  timezone?: string;
  newPassword?: string;
  confirmPassword?: string;
  oldPassword?: string;
}

interface UpdateUserDetailsParams {
  fullName: string;
  email: string;
  locationId: string;
  regionId: string;
  timezoneId: string;
  companyType: string;
  jobTitle: string;
  updatePasswordModel?: {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
  };
}
const AccountSettings = () => {
  const queryClient = useQueryClient();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState<Country | null>(null);
  const [region, setRegion] = useState("");
  const [timezone, setTimezone] = useState<Timezone | null>(null);
  const [companyType, setCompanyType] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [status, setStatus] = useState(200);

  const [errors, setErrors] = useState<ErrorState>({
    fullName: "",
    email: "",
    country: "",
    region: "",
    timezone: "",
    newPassword: "",
    confirmPassword: "",
    oldPassword: "",
  });

  const { data, isLoading } = useQuery<UserDetails>({
    queryKey: ["userDetails"],
    queryFn: () => getUserDetails(),
  });
  const { data: timeZones } = useQuery({
    queryKey: ["onboardedTimeZoneOrganisation"],
    queryFn: () => getTimezoneOnboardedOrganisation(),
  });
  useEffect(() => {
    if (!isLoading && data) {
      setFullName(data.fullName || "");
      setEmail(data.email || "");
      const selectedCountry =
        data.countries.find((el) => el.isSelected) || null;
      const selectedTimezone =
        data.timezones.find((el) => el.isSelected) || null;
      setCountry(selectedCountry);
      setRegion(data.regions.find((el) => el.isSelected)?.id || "");
      setTimezone(selectedTimezone);
      setCompanyType(data?.companyType || "");
      setJobTitle(data?.jobTitle || "");
    }
  }, [isLoading, data]);

  const { mutate, isPending } = useMutation({
    mutationFn: updateUserDetails,
    onSuccess: async () => {
      queryClient.refetchQueries({ queryKey: ["userDetails"] });
      setResponseMessage("Details Updated");
      setStatus(200);
    },
    onError: () => {
      setResponseMessage("Error occurred");
      setStatus(400);
    },
  });

  const validate = () => {
    const newErrors: ErrorState = {
      fullName: "",
      email: "",
      country: "",
      region: "",
      timezone: "",
      newPassword: "",
      confirmPassword: "",
      oldPassword: "",
    };

    if (!fullName) newErrors.fullName = "Full name is required.";
    if (!email) newErrors.email = "Email is required.";
    if (!country) newErrors.country = "Country is required.";
    if (!region) newErrors.region = "Region is required.";
    if (!timezone) newErrors.timezone = "Timezone is required.";
    if (oldPassword || newPassword || confirmPassword) {
      if (!oldPassword) newErrors.oldPassword = "old password is required.";
      if (!newPassword) newErrors.newPassword = "New password is required.";
      if (!confirmPassword)
        newErrors.confirmPassword = "Confirm password is required.";
      if (newPassword !== confirmPassword)
        newErrors.confirmPassword = "Passwords do not match.";
    }

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleSave = () => {
    if (!validate()) return;

    // Ensure all required fields are present
    if (!country?.id || !region || !timezone?.id) {
      return;
    }

    const baseData: Omit<UpdateUserDetailsParams, "updatePasswordModel"> = {
      fullName,
      email,
      locationId: country.id,
      regionId: region,
      timezoneId: timezone.id,
      companyType,
      jobTitle,
    };

    const updatedData: UpdateUserDetailsParams = oldPassword
      ? {
          ...baseData,
          updatePasswordModel: {
            currentPassword: oldPassword,
            newPassword: newPassword,
            confirmNewPassword: newPassword,
          },
        }
      : baseData;

    mutate(updatedData);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <section className="sectionCenter">
      <Box sx={{ p: 4 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
          Your settings
        </Typography>
        <Divider sx={{ mb: 4 }} />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Account
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Full Name *"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  error={!!errors.fullName}
                  helperText={errors.fullName}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email *"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!errors.email}
                  helperText={errors.email}
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete<Country, false>
                  fullWidth
                  options={data?.countries || []}
                  getOptionLabel={(option) => option.title}
                  value={country}
                  onChange={(_, newValue) => setCountry(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country *"
                      error={!!errors.country}
                      helperText={errors.country}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!errors.region}
                >
                  <InputLabel>Region *</InputLabel>
                  <Select
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                    label="Region *"
                  >
                    {data?.regions.map((region) => (
                      <MenuItem key={region.id} value={region.id}>
                        {region.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.region && (
                    <Typography color="error">{errors.region}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete<Timezone, false>
                  fullWidth
                  options={data?.timezones || []}
                  getOptionLabel={(option) => option.title}
                  value={timezone}
                  onChange={(_, newValue) => setTimezone(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Timezone *"
                      error={!!errors.timezone}
                      helperText={errors.timezone}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Company Type"
                  value={companyType}
                  onChange={(e) => setCompanyType(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Job Title"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Update password
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Old password"
                  type={showPassword ? "text" : "password"}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="New password"
                  type={showPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  error={!!errors.newPassword}
                  helperText={errors.newPassword}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Confirm password"
                  type={showPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Box mt={4} display="flex">
              <Button text=" Back" backgroundColor="white" />
              <Button text="Save" onClick={handleSave} />
            </Box>
          </Grid>
        </Grid>
        <Toast message={responseMessage} status={status} />
      </Box>
    </section>
  );
};

export default AccountSettings;
