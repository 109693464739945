import React, { useState } from "react";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { format, subDays } from "date-fns";
import UserTab from "./User";
import ContentTab from "./Content";
import NotificationTab from "./Notification";
import DateFilter from "./DateFilter";
import OrganisatioFilter from "./OrganisationFilter";
import { getRole } from "../../../helpers/auth";
import { roles } from "../../../constants/routes";
import { useOrganisation } from "../../../hooks/useOrganisation";

const StatiscticsPage = () => {
  const { primaryColor } = useOrganisation();

  const [tabIndex, setTabIndex] = useState(0);
  const [dateRange, setDateRange] = useState("Last Week");
  const [startDate, setStartDate] = useState(
    format(subDays(new Date(), 7), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [organisation, setOrganisation] = useState("");

  const role = getRole();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const renderSettingTab = () => {
    switch (tabIndex) {
      case 0:
        return (
          <ContentTab
            tabIndex={0}
            index={0}
            type={"Content"}
            key={"allContent"}
            startDate={new Date(startDate).toISOString()}
            endDate={new Date(endDate).toISOString()}
            organisation={organisation?.id}
            {...a11yProps(0)}
          />
        );
      case 1:
        return (
          <NotificationTab
            tabIndex={0}
            index={0}
            type={"Notifications"}
            key={"allNotifications"}
            startDate={startDate}
            endDate={endDate}
            organisation={organisation?.id}
            {...a11yProps(0)}
          />
        );
      case 2:
        return (
          <UserTab
            tabIndex={2}
            index={2}
            type={"Users"}
            key={"allUsers"}
            startDate={new Date(startDate).toISOString()}
            endDate={new Date(endDate).toISOString()}
            organisation={organisation?.id}
            {...a11yProps(0)}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          padding: "24px 0px",
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          style={{ marginBottom: 8 }}
          textColor="inherit"
          TabIndicatorProps={{
            style: { backgroundColor: primaryColor },
          }}
        >
          <Tab
            label="Content"
            sx={{
              color: tabIndex === 0 ? primaryColor : "inherit",
            }}
          />
          <Tab
            label="Notifications"
            sx={{
              color: tabIndex === 1 ? primaryColor : "inherit",
            }}
          />
          <Tab
            label="Users"
            sx={{
              color: tabIndex === 2 ? primaryColor : "inherit",
            }}
          />
        </Tabs>
        <DateFilter
          dateRange={dateRange}
          setDateRange={setDateRange}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>
      {role === roles.SuperAdmin && (
        <Grid container xs={12}>
          <OrganisatioFilter
            organisation={organisation}
            setOrganisation={setOrganisation}
          />
        </Grid>
      )}
      {renderSettingTab()}
    </Box>
  );
};

export default StatiscticsPage;
