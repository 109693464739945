import React, { useEffect } from "react";
import AdminDashboardPage from "../../../compnents/pages/AdminDashboard";
import { Typography } from "@mui/material";

const AdminDashboard = () => {
  console.log("first");
  useEffect(() => {
    document.title = `AdminDashboard`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="main" tabIndex={0}>
      <section className="sectionCenter">
        <Typography variant="h1" fontSize={24} fontWeight={"bold"}>
          Dashboard
        </Typography>
        <hr />
        <AdminDashboardPage />
      </section>
    </main>
  );
};

export default AdminDashboard;
