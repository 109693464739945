import { Snackbar, Alert } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import * as signalR from "@microsoft/signalr";
import { RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useOrganisation } from "../hooks/useOrganisation";
import { router } from "./router/route";
import { useMutation } from "@tanstack/react-query";
import { canViewNotification } from "../store/api/analytics";
import WebFont from "webfontloader";
import Loading from "../compnents/common/Loading";

interface NotificationState {
  open: boolean;
  message: string;
  url: string;
}
interface NotificationData {
  title: string;
  id: string;
  image: string;
  vendor: string;
}
function AppRoutes() {
  const companyName = localStorage.getItem("companyName");
  const organisationId = localStorage.getItem("organisationId");
  const {
    primaryColor,
    primaryColorLight,
    primaryColorDark,
    primaryFont,
    isFetched,
  } = useOrganisation();
  const [fontLoaded, setFontLoaded] = useState(false);
  const [notification, setNotification] = useState<NotificationState | null>(
    null
  );
  const [notificationData, setNotificationData] =
    useState<NotificationData | null>(null);
  const [notificationPermission, setNotificationPermission] =
    useState<NotificationPermission>("default");

  const routerInstance = useMemo(() => router(), []);

  // Request notification permissions on component mount
  useEffect(() => {
    const requestNotificationPermission = async () => {
      try {
        // Check if the browser supports notifications
        if (!("Notification" in window)) {
          console.log("This browser does not support desktop notifications");
          return;
        }

        // Request permission
        const permission = await Notification.requestPermission();
        setNotificationPermission(permission);

        // Store permission in localStorage to persist across sessions
        localStorage.setItem("notificationPermission", permission);
      } catch (error) {
        console.error("Error requesting notification permission:", error);
      }
    };

    // Check if we already have permission stored
    const storedPermission = localStorage.getItem(
      "notificationPermission"
    ) as NotificationPermission;
    if (storedPermission) {
      setNotificationPermission(storedPermission);
    } else {
      requestNotificationPermission();
    }
  }, []);
  useEffect(() => {
    if (isFetched) {
      // Apply other styles
      document.documentElement.style.setProperty(
        "--primary-color",
        primaryColor
      );
      document.documentElement.style.setProperty(
        "--primary-light",
        primaryColorLight
      );
      document.documentElement.style.setProperty(
        "--primary-dark",
        primaryColorDark
      );
    }
  }, [primaryColor, primaryColorLight, primaryColorDark, isFetched]);

  useEffect(() => {
    if (primaryFont) {
      WebFont.load({
        google: {
          families: [primaryFont],
        },
        active: () => {
          setFontLoaded(true);
        },
        inactive: () => {
          console.warn(`${primaryFont} failed to load`);
        },
      });
    }
  }, [primaryFont]);
  const showNotification = useMemo(
    () =>
      ({
        title,
        id,
        image,
        vendor,
      }: {
        title: string;
        id: string;
        image: string;
        vendor: string;
      }) => {
        const url = `https://komz.tech/${companyName}/article/details/${id}?ComingFrom=${"push"}`;
        console.log("show notification", { title, id, image, vendor });

        // Only show system notification if permission is granted
        if (notificationPermission === "granted") {
          try {
            const options: NotificationOptions = {
              body: `From: ${vendor}`,
              icon: image || "/path/to/default-icon.png",
              badge: "/path/to/badge-icon.png",
              tag: id,
              requireInteraction: true,
              silent: false,
            };

            const browserNotification = new Notification(title, options);

            browserNotification.onclick = () => {
              window.open(url, "_blank");
              browserNotification.close();
            };

            // Handle notification close
            browserNotification.onclose = () => {
              setNotification(null);
            };
          } catch (error) {
            console.error("Error showing notification:", error);
          }
        }

        // Always show in-app notification
        setNotification({
          open: true,
          message: `${title} - From: ${vendor}`,
          url,
        });
      },
    []
  );
  const handleShowNotifion = ({
    title,
    id,
    image,
    vendor,
  }: {
    title: string;
    id: string;
    image: string;
    vendor: string;
  }) => {
    canViewNotificationMutate({ contentId: id });
    setNotificationData({
      title,
      id,
      image,
      vendor,
    });
  };

  const { mutate: canViewNotificationMutate } = useMutation({
    mutationFn: canViewNotification,
    onSuccess: (data) => {
      if (data?.canView) {
        console.log("first");
        showNotification({
          title: notificationData?.title || "",
          id: notificationData?.id || "",
          image: notificationData?.image || "",
          vendor: notificationData?.vendor || "",
        });
      }
    },
    onError: (error) => {
      console.log(error);
      alert("Failed to add content");
    },
  });

  useEffect(() => {
    if (!organisationId) return;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://komz.azurewebsites.net/notificationHub")
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then(() => {
        console.log("Connected to SignalR");
        connection.invoke("JoinGroup", organisationId).then(() => {
          console.log(`Joined group: ${organisationId}`);
        });

        connection.on("ReceiveMessage", handleShowNotifion);
      })
      .catch((err) => console.error("SignalR Connection Error: ", err));

    return () => {
      connection.stop().then(() => console.log("Disconnected from SignalR"));
    };
  }, [organisationId, showNotification]);

  const handleNotificationClick = () => {
    if (notification?.url) {
      window.open(notification.url, "_blank");
    }
    setNotification(null);
  };
  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: primaryFont || "DM Sans",
        },
      }),
    [primaryFont]
  );
  // Show loading state while font is loading
  if (primaryFont && !fontLoaded) {
    return <Loading />;
  }
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={routerInstance} />
      {notification && (
        <Snackbar
          open={notification.open}
          autoHideDuration={60000}
          onClose={() => setNotification(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            severity="info"
            onClick={handleNotificationClick}
            sx={{ cursor: "pointer", width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}
    </ThemeProvider>
  );
}

export default AppRoutes;
