import { Autocomplete, Grid, InputLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import { getOrganisation } from "../../../../store/api/organisation";
import { useQuery } from "@tanstack/react-query";
import { getRole } from "../../../../helpers/auth";
import { roles } from "../../../../constants/routes";

const OrganisatioFilter = ({ setOrganisation }) => {
  const role = getRole();
  const [search, setSearch] = useState("");
  const { data: organisationList } = useQuery({
    queryKey: ["organisationList", search],
    queryFn: () => getOrganisation({ search }),
    enabled: role === roles.SuperAdmin,
  });

  return (
    <Grid item xs={12} md={6} m={2}>
      <InputLabel>Select Organisation</InputLabel>

      <Autocomplete
        options={organisationList || []}
        getOptionLabel={(option) => option.title || ""}
        onChange={(event, newValue) => {
          if (newValue) {
            setOrganisation(newValue);
            setSearch("");
          } else {
            setOrganisation("");
          }
        }}
        inputValue={search}
        onInputChange={(e, newInputValue) => setSearch(newInputValue)}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    </Grid>
  );
};

export default OrganisatioFilter;
