import * as React from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { useOrganisation } from "../../../hooks/useOrganisation";
import { getTextColor } from "../../../helpers";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});

export default function Pagination({ count, setCurrentPage, currentPage }) {
  const { items } = usePagination({
    count: count,
  });
  const { primaryColor } = useOrganisation();
  const textColor = getTextColor(primaryColor) || "black";

  return (
    <nav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <button
                type="button"
                style={{
                  fontWeight: selected ? "bold" : undefined,
                  background: selected ? primaryColor : "none",
                  color: selected ? textColor : "black",
                  borderRadius: 8,
                  marginRight: 4,
                  border: "none",
                }}
                {...item}
              >
                {page}
              </button>
            );
          } else if (type === "previous" || type === "next") {
            children = (
              <button
                type="button"
                {...item}
                style={{ background: "transparent", border: "none" }}
              >
                {type}
              </button>
            );
          } else {
            children = (
              <button type="button" {...item}>
                {type}
              </button>
            );
          }

          return (
            <li key={index} onClick={() => setCurrentPage(page)}>
              {children}
            </li>
          );
        })}
      </List>
    </nav>
  );
}
