import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import Component from "./Component";
import {
  getAllTypes,
  addType,
  editType,
  deleteType,
  restoreType,
} from "../../../store/api/type";
import {
  getAllTopic,
  addTopic,
  editTopic,
  deleteTopic,
  restoreTopic,
} from "../../../store/api/topic";
import {
  getAllRegions,
  addRegion,
  editRegion,
  deleteRegion,
  restoreRegion,
} from "../../../store/api/region";
import ResourceList from "./Resources";

const Config = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const renderSettingTab = () => {
    switch (tabIndex) {
      case 0:
        return (
          <Component
            tabIndex={0}
            index={0}
            type={"type"}
            getData={getAllTypes}
            addFunction={addType}
            editFunction={editType}
            deleteFunction={deleteType}
            restoreFunction={restoreType}
            keytype={"allTypes"}
          />
        );
      case 1:
        return (
          <Component
            tabIndex={1}
            index={1}
            type={"topic"}
            getData={getAllTopic}
            addFunction={addTopic}
            editFunction={editTopic}
            deleteFunction={deleteTopic}
            restoreFunction={restoreTopic}
            keytype={"allTopics"}
          />
        );
      case 2:
        return (
          <Component
            tabIndex={2}
            index={2}
            type={"region"}
            getData={getAllRegions}
            addFunction={addRegion}
            editFunction={editRegion}
            deleteFunction={deleteRegion}
            restoreFunction={restoreRegion}
            keytype={"allRegions"}
          />
        );
      case 3:
        return <Component tabIndex={3} index={3} keytype={""} />;
      case 4:
        return <ResourceList tabIndex={3} index={3} keytype={""} />;
      default:
        <></>;
    }
  };
  return (
    <Box>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        style={{ marginBottom: 8 }}
      >
        <Tab label="Type" />
        <Tab label="Topic" />
        <Tab label="Region" />
        <Tab label="Ads" />
        <Tab label="Resources" />
      </Tabs>

      {renderSettingTab()}
    </Box>
  );
};

export default Config;
