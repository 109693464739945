import React from "react";
import ButtonBase from "@mui/material/Button";
import { Box } from "@mui/material";
import { useOrganisation } from "../../../hooks/useOrganisation";
import { CSSProperties } from "react"; // Importing CSSProperties for better type inference
import { getTextColor } from "../../../helpers";
import Loading from "../Loading";

type buttonProps = {
  text: string;
  width?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  padding?: number;
  backgroundColor?: string | undefined;
  style?: CSSProperties;
  linkExternal?: boolean;
  to?: string;
  type?: "button" | "submit" | "reset"; // Restrict to valid button types
  isLoading?: boolean;
  endIcon?: string;
};

const Button = ({
  text = "",
  width = "100%",
  onClick = () => {},
  disabled = false,
  backgroundColor,
  style = {},
  linkExternal = false,
  to = "",
  type = "button",
  isLoading = false,
  endIcon,
}: buttonProps) => {
  const { primaryColor } = useOrganisation();

  // Ensure primaryColor is not undefined
  const buttonBackgroundColor = backgroundColor || primaryColor || "#96f7dc";
  // Handle external links
  if (linkExternal) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <ButtonBase
          onClick={onClick}
          disabled={disabled}
          style={{
            background: buttonBackgroundColor,
            width: width,
            color: disabled
              ? "gray"
              : getTextColor(buttonBackgroundColor) || "black",
            cursor: disabled ? "not-allowed" : "pointer",
            border:
              buttonBackgroundColor === "white"
                ? `1px solid ${primaryColor}`
                : "inherit",
            ...style,
          }}
          endIcon={endIcon}
          sx={{ textTransform: "capitalize" }}
          variant="outlined"
          className="buttonContainer"
          type={type}
        >
          {isLoading ? <Loading color={"black"} /> : text}
        </ButtonBase>
      </a>
    );
  }

  // Handle normal buttons
  return (
    <Box ml={2} mb={2} mt={1}>
      <ButtonBase
        onClick={onClick}
        disabled={disabled}
        endIcon={endIcon}
        style={{
          background: buttonBackgroundColor,
          width: width,
          color: disabled
            ? "gray"
            : getTextColor(buttonBackgroundColor) || "black",
          cursor: disabled ? "not-allowed" : "pointer",
          border:
            buttonBackgroundColor === "white"
              ? `1px solid ${primaryColor}`
              : "inherit",
          ...style,
        }}
        variant="outlined"
        className="buttonContainer"
        type={type}
        sx={{ textTransform: "capitalize" }}
      >
        {isLoading ? <Loading color={"black"} /> : text}
      </ButtonBase>
    </Box>
  );
};

export default Button;
