import React, { useEffect } from "react";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Box,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  OutlinedInput,
  Chip,
  FormControl,
  InputLabel,
  Grid,
  Container,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useOrganisation } from "../../../hooks/useOrganisation";
// import Button from "../../common/Button";
import { getTextColor } from "../../../helpers";

const FilterBar = ({
  region,
  setRegion,
  topic,
  setTopic,
  search,
  setSearch,
  setIsSearch,
  topicList,
  regionList,
  typeList,
  type,
  setType,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  vendor,
  setVendor,
}) => {
  const { primaryColor } = useOrganisation();
  const textColor = getTextColor(primaryColor) || "black";

  const handleSelectChange = (event, setter, list) => {
    const { value } = event.target;
    if (value.includes("select-all")) {
      setter(list.map((el) => el.id));
      setIsSearch(true);
      return;
    }
    if (value.includes("unselect-all")) {
      setter([]);
      return;
    }
    setIsSearch(true);
    setter(value);
  };

  useEffect(() => {
    if (startDate && endDate) {
      setIsSearch(true);
    }
  }, [startDate, endDate]);

  const handleClearAll = () => {
    setRegion([]);
    setTopic([]);
    setType([]);
    setSearch("");
    setStartDate(null);
    setEndDate(null);
    setIsSearch(false);
  };

  const renderSelectValue = (selected, list) => {
    if (selected?.length === 0) {
      return null;
    }
    return (
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: 0.5 }}>
        {selected?.slice(0, 2).map((value) => {
          const item = list.find((el) => el.id === value);
          return (
            <Chip
              key={value}
              label={item?.title || value}
              sx={{
                height: "24px",
                backgroundColor: primaryColor,
                color: textColor,
              }}
            />
          );
        })}
        {selected?.length > 2 && <span style={{ color: "#aaa" }}>...</span>}
      </Box>
    );
  };
  const selectStyle = {
    minWidth: { xs: "100%" },
    mb: { xs: 1, sm: 0 },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: primaryColor,
    },
    "& .MuiSelect-multiple": {
      "& .MuiMenuItem-root.Mui-selected": {
        backgroundColor: `${primaryColor}20`,
      },
    },
  };

  const menuItemStyle = {
    "&.Mui-selected": {
      backgroundColor: `${primaryColor}20`,
      "&:hover": {
        backgroundColor: `${primaryColor}30`,
      },
    },
    "&:hover": {
      backgroundColor: `${primaryColor}10`,
    },
  };
  return (
    <div className="sectionCenter">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container maxWidth="xl">
          <Grid
            container
            justifyContent={"space-between"}
            spacing={2}
            sx={{ py: 2 }}
          >
            {/* Left side filters */}
            <Grid item xs={12} md={7}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl size="small" fullWidth sx={selectStyle}>
                    <InputLabel>Region</InputLabel>
                    <Select
                      label="Region"
                      multiple
                      value={region}
                      onChange={(e) =>
                        handleSelectChange(e, setRegion, regionList)
                      }
                      input={<OutlinedInput />}
                      renderValue={(selected) =>
                        renderSelectValue(selected, regionList)
                      }
                      MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                    >
                      <MenuItem value="select-all" sx={menuItemStyle}>
                        Select All
                      </MenuItem>
                      <MenuItem value="unselect-all" sx={menuItemStyle}>
                        Unselect All
                      </MenuItem>
                      {regionList?.map((el) => (
                        <MenuItem key={el.id} value={el.id} sx={menuItemStyle}>
                          {el.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FormControl size="small" fullWidth sx={selectStyle}>
                    <InputLabel>Topic</InputLabel>
                    <Select
                      label="Topic"
                      multiple
                      value={topic}
                      onChange={(e) =>
                        handleSelectChange(e, setTopic, topicList)
                      }
                      input={<OutlinedInput />}
                      renderValue={(selected) =>
                        renderSelectValue(selected, topicList)
                      }
                      MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                    >
                      <MenuItem value="select-all" sx={menuItemStyle}>
                        Select All
                      </MenuItem>
                      <MenuItem value="unselect-all" sx={menuItemStyle}>
                        Unselect All
                      </MenuItem>
                      {topicList?.map((el) => (
                        <MenuItem key={el.id} value={el.id} sx={menuItemStyle}>
                          {el.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FormControl size="small" fullWidth sx={selectStyle}>
                    <InputLabel>Type</InputLabel>
                    <Select
                      label="Type"
                      multiple
                      value={type}
                      onChange={(e) => handleSelectChange(e, setType, typeList)}
                      input={<OutlinedInput />}
                      renderValue={(selected) =>
                        renderSelectValue(selected, typeList)
                      }
                      MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                    >
                      <MenuItem value="select-all" sx={menuItemStyle}>
                        Select All
                      </MenuItem>
                      <MenuItem value="unselect-all" sx={menuItemStyle}>
                        Unselect All
                      </MenuItem>
                      {typeList?.map((el) => (
                        <MenuItem key={el.id} value={el.id} sx={menuItemStyle}>
                          {el.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    size="small"
                    variant="outlined"
                    placeholder="Search"
                    fullWidth
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setIsSearch(true);
                    }}
                    sx={selectStyle}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Right side date pickers and clear button */}
            <Grid item xs={12} md={4}>
              <Grid
                container
                display={"flex"}
                alignItems={"start"}
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item xs={12} sm={4} md={4}>
                  <MuiDatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                    maxDate={endDate}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <MuiDatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                    minDate={startDate}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Button
                    onClick={handleClearAll}
                    style={{
                      textTransform: "none",
                      width: "80%",
                      // borderColor: primaryColor,
                      backgroundColor: primaryColor,
                      color: textColor,
                      height: "40px",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    Clear All
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </LocalizationProvider>
    </div>
  );
};

export default FilterBar;
