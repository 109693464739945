const BaseURL = "https://komz.azurewebsites.net/api";
const PREFIX = "";

//TODO to add condition on baseurl depends on the env

export const URL = {
  ANALYTICS: {
    BASE: BaseURL,
    //reader-editor-admin-superadmin
    V1_ANALYTICS_USER_CONTENT_SHARE: PREFIX + "/Content/Share",
    //reader-editor-admin-superadmin
    V1_ANALYTICS_USER_CONTENT_READ: PREFIX + "/Content/Read",
    V1_ANALYTICS_USER_EMAIL_CONTENT_READ: PREFIX + "/Content/Email/Click",
    V1_ANALYTICS_USER_NOTIFICATION_CONTENT_READ: PREFIX + "/Content/Push/Click",
    V1_ANALYTICS_USER_NOTIFICATION_CAN_VIEW: PREFIX + "/Content/CanViewContent",
  },
  CONTENT: {
    BASE: BaseURL,
    //editor-admin
    V1_EXTRACT_CONTENT: PREFIX + "/Content/extract",
    //editor-admin
    V1_ADD_CONTENT: PREFIX + "/Content",
    //editor-admin
    V1_EDIT_CONTENT: PREFIX + "/Content",
    V1_EDIT_TEMPLATE_CONTENT: PREFIX + "/Content/GetUpdateTemplate",
    //editor-admin
    V1_GET_TOPICS_REGIONS_TYPES: PREFIX + "/Content/GetCreationTemplate",
    //reader-editor-admin-superadmin
    V1_GET_CONTENT_BY_ID: PREFIX + "/Content/ViewContent",
    //editor-admin-superadmin
    V1_ADMIN_GET_ALL_CONTENT: PREFIX + "/Content/getAdminList",
    //admin
    V1_APPROVE_ADMIN_CONTENT: PREFIX + "/Content/Approve",
    //admin
    V1_REJECT_ADMIN_CONTENT: PREFIX + "/Content/RejectContent",
    V1_ADMIN_CEHCK_APPROVAL_CONTENT: PREFIX + "/Content/ValidateApprove",
    //admin
    V1_DELETE_ADMIN_CONTENT: PREFIX + "/Content/DeleteContent",
    //editor-admin
    V1_GET_ADMIN_NEWSELETTER: PREFIX + "/Content/getAdminNewsletterList",
    //editor-admin
    V1_GET_ORDRED_ADMIN_NEWSELETTER:
      PREFIX + "/Content/getOrdredNewsletterList",
    //editor-admin
    V1_ADMIN_REORDER_NEWSELETTER: PREFIX + "/Content/UpdatePriority",
    //editor-admin
    V1_ADMIN_GET_SEARCH_ADS_CONTENT: PREFIX + "/Content/Ads/SearchContent",
    //reader-editor-admin
    V1_ADMIN_GET_LIST_ADS: PREFIX + "/Content/Ads/list",
    //editor-admin
    V1_ADMIN_GET_CREATE_TEMPLATE_ADS:
      PREFIX + "/Content/Ads/GetAdCreationTemplate",
    //editor-admin
    V1_ADMIN_GET_UPDATE_TEMPLATE_ADS:
      PREFIX + "/Content/Ads/GetAdUpdateTemplate",
    //editor-admin
    V1_ADMIN_UPDATE_ADS: PREFIX + "/Content/Ads/Update",
    //editor-admin
    V1_ADMIN_DELETE_ADS: PREFIX + "/Content/Ads/Delete",
    //editor-admin
    V1_ADMIN_POST_ADS: PREFIX + "/Content/Ads/Create",
    //editor-admin
    V1_ADMIN_BROADCAST_CONTENT: PREFIX + "/Content/BroadCast",

    //reader-editor-admin
    V1_USER_GET_ALL_CONTENT: PREFIX + "/Content/list",
    //reader-editor-admin
    V1_USER_SEARCH_CONTENT_TEMPLATE: PREFIX + "/Content/GetSearchTemplate",
    V1_USER_SEARCH_VENDOR_CONTENT_TEMPLATE:
      PREFIX + "/Content/GetSearchUserTemplate",
    //reader-editor-admin
    V1_USER_SEARCH_CONTENT: PREFIX + "/Content/search",
  },
  FEED: {
    BASE: BaseURL,
    V1_ADD_RSS_FEED: PREFIX + "/Rss",
    V1_VALIDATE_RSS_FEED: PREFIX + "/Rss/Validate",
    V1_GET_ALL_RSS_FEED: PREFIX + "/Rss/getFeedList",
    V1_EDIT_RSS_FEED: PREFIX + "/Rss",
    V1_GET_CREATION_TEMPLATE_RSS_FEED: PREFIX + "/Rss/GetCreationTemplate",
    V1_GET_UPDATE_TEMPLATE_RSS_FEED: PREFIX + "/Rss/GetUpdateTemplate",
    V1_ACTIVATE_RSS_FEED: PREFIX + "/Rss/Activate",
    V1_DELETE_RSS_FEED: PREFIX + "/Rss/Delete",
  },

  MANAGE_USERS: {
    BASE: BaseURL,
    //admin-superadmin
    V1_GET_ALL_USERS: PREFIX + "/User/getAdminList",
    //admin-superadmin
    V1_ADD_USER: PREFIX + "/User",
    //admin-superadmin
    V1_GET_CREATION_TEMPLATE_USER: PREFIX + "/User/GetCreationTemplate",
    //admin-superadmin
    V1_GET_USER_BY_ID: PREFIX + "/User/GetUpdateTemplate",
    //admin-superadmin
    V1_DELETE_ADMIN_USERS: PREFIX + "/User/DeleteUsers",
    //admin-superadmin
    V1_ADMIN_UPLOAD_USERS: PREFIX + "/User/BulkUlpoad",
    //reader-editor-admin
    V1_GET_USER_PREFERENCES: PREFIX + "/User/GetPreferencesTemplate",
    //reader-editor-admin
    V1_UPDATE_USER_PREFERENCE: PREFIX + "/User/UpdateUserPreferences",
    //reader-editor-admin
    V1_GET_USER_DETAILS: PREFIX + "/User/GetUpdateUserDetailsTemplate",
    //reader-editor-admin
    V1_UPDATE_USER_DETAILS: PREFIX + "/User/UpdateUserDetails",
    //reader-editor-admin
    V1_GET_USER_NOTIFICATION:
      PREFIX + "/User/GetUpdateUserNotificationsTemplate",
    //reader-editor-admin
    V1_UPDATE_USER_NOTIFICATION: PREFIX + "/User/UpdateNotifications",
  },
  MANAGE_TOPICS: {
    BASE: BaseURL,
    //editor-admin
    V1_GET_ALL_TOPICS: PREFIX + "/ContentTopic/getTopicList",
    //editor-admin
    V1_GET_TOPIC_BY_ID: PREFIX + "/ContentTopic/GetUpdateTemplate",
    //editor-admin
    V1_ADD_TOPIC: PREFIX + "/ContentTopic",
    //editor-admin
    V1_EDIT_TOPIC: PREFIX + "/ContentTopic",
    //editor-admin
    V1_DELETE_TOPIC: PREFIX + "/ContentTopic/DeleteTopics",
    //editor-admin
    V1_RESTORE_TOPIC: PREFIX + "/ContentTopic/RestoreTopics",
  },
  MANAGE_REGIONS: {
    BASE: BaseURL,
    //editor-admin
    V1_GET_ALL_REGIONS: PREFIX + "/ContentRegion/getRegionList",
    //editor-admin
    V1_GET_REGION_BY_ID: PREFIX + "/ContentRegion/GetUpdateTemplate",
    //editor-admin
    V1_ADD_REGION: PREFIX + "/ContentRegion",
    //editor-admin
    V1_EDIT_REGION: PREFIX + "/ContentRegion",
    //editor-admin
    V1_DELETE_REGION: PREFIX + "/ContentRegion/DeleteRegions",
    //editor-admin
    V1_RESTORE_REGION: PREFIX + "/ContentRegion/RestoreRegions",
  },
  MANAGE_TYPE: {
    BASE: BaseURL,
    //editor-admin
    V1_GET_ALL_TYPES: PREFIX + "/ContentType/getTypeList",
    //editor-admin
    V1_GET_TYPE_BY_ID: PREFIX + "/ContentType/GetUpdateTemplate",
    //editor-admin
    V1_ADD_TYPE: PREFIX + "/ContentType",
    //editor-admin
    V1_EDIT_TYPE: PREFIX + "/ContentType",
    //editor-admin
    V1_DELETE_TYPE: PREFIX + "/ContentType/DeleteTypes",
    //editor-admin
    V1_RESTORE_TYPE: PREFIX + "/ContentType/RestoreTypes",
  },
  ORGANISATION: {
    BASE: BaseURL,
    //superadmin
    V1_SUPERADMIN_GET_ALL_ORGANISATION:
      PREFIX + "/Organisation/getOrganisationList",
    V1_SUPERADMIN_ADD_ORGANISATION: PREFIX + "/Organisation",
    V1_SUPERADMIN_ADMIN_UPDATE_ORGANISATION: PREFIX + "/Organisation",
    V1_SUPERADMIN_DELETE_ORGANISATION:
      PREFIX + "/Organisation/DeleteOrganisations",
    V1_SUPERADMIN_ADMIN_GET_UPDATE_TEMPLATE_ORGANISATION:
      PREFIX + "/Organisation/GetUpdateTemplate",
    V1_SUPERADMIN_ADMIN_AUTOCOMPLETE_ORGANISATION:
      PREFIX + "/Organisation/AutoComplete",
    V1_ADMIN_ONBOARD_ORGANISATION: PREFIX + "/Organisation/Onboard",
    V1_ADMIN_UPDATE_ONBOARD_ORGANISATION:
      PREFIX + "/Organisation/Onboard/GetUpdateTemplate",

    V1_ADMIN_GET_ONBOARDED_ORGANISATION: PREFIX + "/Organisation/Onboard",
    V1_ADMIN_GET_TIMEZONE_ORGANISATION:
      PREFIX + "/User/GetSearchTimeZonesTemplate",
    V1_ADMIN_GET_TOPICS_ORGANISATION: PREFIX + "/Organisation/GetTopics",
    V1_ADMIN_VERIFY_ORGANISATION: PREFIX + "/Organisation/Verifie",
    FONT_API_URL: "https://www.googleapis.com/webfonts/v1/webfonts",
  },
  RESOURCE: {
    BASE: BaseURL,
    V1_ADMIN_GET_RESOURCES_LIST: PREFIX + "/library/getDocumentList",
    V1_ADMIN_ADD_RESOURCES: PREFIX + "/library",
    V1_ADMIN_DELETE_RESOURCES: PREFIX + "/library",
  },
  STATISTICS: {
    BASE: BaseURL,
    //admin-superadmin
    V1_ADMIN_USER_STATISTICS: PREFIX + "/Statistics/Users",
    //admin-superadmin
    V1_ADMIN_USER_LOGINS: PREFIX + "/Statistics/Users/Logins",
    //admin-superadmin
    V1_ADMIN_USER_STATISTICS_ACTIVE: PREFIX + "/Statistics/Users/Active",
    //admin-superadmin
    V1_ADMIN_USER_STATISTICS_UNACTIVE: PREFIX + "/Statistics/Users/UnActive",
    //admin-superadmin
    V1_ADMIN_USER_STATISTICS_SUBSCRIPTION:
      PREFIX + "/Statistics/Users/Subscription",
    //admin-superadmin
    V1_ADMIN_USER_STATISTICS_UNSUBSCRIPTION:
      PREFIX + "/Statistics/Users/UnSubscription",
    //admin-superadmin
    V1_ADMIN_USER_STATISTICS_BOUNCES: PREFIX + "/Statistics/Users/Bounces",

    //admin-superadmin
    V1_ADMIN_CONTENT_STATISTICS: PREFIX + "/Statistics/Contents",
    //admin-superadmin
    V1_ADMIN_CONTENT_STATISTICS_CREATED:
      PREFIX + "/Statistics/Contents/Created",
    //admin-superadmin
    V1_ADMIN_CONTENT_STATISTICS_SHARED: PREFIX + "/Statistics/Contents/Shared",
    //admin-superadmin
    V1_ADMIN_CONTENT_STATISTICS_VIEWS: PREFIX + "/Statistics/Contents/Views",

    //admin-superadmin
    V1_ADMIN_NOTIFICATIONS_STATISTICS: PREFIX + "/Statistics/Notifications",
  },
  USER_PROFILES: {
    BASE: BaseURL,
    //reader-editor-admin
    V1_USER_LOGIN: PREFIX + "/Account/login",
    //reader-editor-admin-superadmin
    V1_SUPERADMIN_LOGIN: PREFIX + "/Account/login",
    //reader-editor-admin-superadmin
    V1_USER_FORGET_PASSWORD: PREFIX + "/Account/request",
    //reader-editor-admin-superadmin
    V1_USER_RESET_PASSWORD: PREFIX + "/Account/reset",
    //reader-editor-admin-superadmin
    V1_USER_REFRESH_TOKEN: PREFIX + "/Account/refresh",
  },
};
