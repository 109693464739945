import { useCallback, useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import Loading from "../../../common/Loading";
import ArticleCard from "../../../common/ArticleCard";

import { getAllArticles } from "../../../../store/api/content";

const PaginatedContent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["articlesPagination"],
    queryFn: ({ pageParam = "" }) =>
      getAllArticles({ continuationToken: pageParam }),
    getNextPageParam: (lastPage) => lastPage.continuationToken || null,
  });
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.scrollHeight - 20
    ) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  if (isLoading) return <Loading />;
  if (error)
    return <Typography variant="body1">Error loading articles</Typography>;

  return (
    <section className="sectionCenter">
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={isMobile ? 1 : 2}>
          {data?.pages.flatMap((page) =>
            page.paginatedContent.map((article, i) => (
              <ArticleCard
                article={article}
                key={article.id}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            ))
          )}
          {isFetchingNextPage && <Loading />}
        </Grid>
      </Box>
    </section>
  );
};

export default PaginatedContent;
