import React, { useState, useRef } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  LinearProgress,
  IconButton,
  Paper,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import { allowedTypes } from "../../../../constants/types";
import { addResource } from "../../../../store/api/resources";

const UploadResource = ({ open, onClose }) => {
  const queryClient = useQueryClient();

  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);

  const { mutate: addResourceMutate } = useMutation({
    mutationFn: addResource,
    onSuccess: (data) => {
      queryClient.refetchQueries("resourcesList");
      handleCloseAfterSuccess();
    },
    onError: () => {
      alert("Failed to add content");
      setIsUploading(false);
      setUploadProgress(0);
    },
  });

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file && allowedTypes.includes(file.type)) {
      setSelectedFile(file);
      setFileName(file.name);
      setIsUploading(true);

      try {
        // Start progress animation
        const progressInterval = setInterval(() => {
          setUploadProgress((prev) => Math.min(prev + 10, 90));
        }, 200);

        const base64Data = await convertFileToBase64(file);

        clearInterval(progressInterval);
        setUploadProgress(100);

        const fileData = {
          fileName: file.name,
          contentType: file.type,
          fileData: base64Data,
        };
        addResourceMutate(fileData);
      } catch (error) {
        console.error("Upload failed:", error);
        alert("Upload failed. Please try again.");
        setIsUploading(false);
        setUploadProgress(0);
      }
    } else {
      alert("Please select a valid file type");
    }
  };

  const handleCloseAfterSuccess = () => {
    setFileName("");
    setSelectedFile(null);
    setUploadProgress(0);
    setIsUploading(false);
    onClose();
  };

  const handleClose = () => {
    if (!isUploading) {
      handleCloseAfterSuccess();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown={isUploading}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          Upload File
          <IconButton onClick={handleClose} size="small" disabled={isUploading}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ p: 2 }}>
          <TextField
            fullWidth
            label="File Name"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            margin="normal"
            disabled={true}
          />

          <Paper
            variant="outlined"
            sx={{
              mt: 2,
              p: 3,
              border: "2px dashed",
              borderColor: "grey.300",
              textAlign: "center",
              cursor: isUploading ? "not-allowed" : "pointer",
              opacity: isUploading ? 0.7 : 1,
            }}
            onClick={() => !isUploading && fileInputRef.current?.click()}
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileSelect}
              accept=".pdf,.docx,.doc,.xlsx,.xls,.jpg,.png,.zip,.ppt,.pptx,.mp4,.mov"
              disabled={isUploading}
            />

            <CloudUploadIcon
              sx={{ fontSize: 48, color: "text.secondary", mb: 2 }}
            />
            <Typography variant="body1" gutterBottom>
              {selectedFile ? selectedFile.name : "Click to select file"}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Allowed file types: pdf, docx, doc, xlsx, xls, jpg, png, zip, ppt,
              pptx, mp4, mov
            </Typography>
          </Paper>

          {isUploading && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Uploading... {uploadProgress}%
              </Typography>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isUploading}>
          Cancel
        </Button>
        <Button variant="contained" disabled={true}>
          {isUploading ? "Uploading..." : "Upload"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadResource;
