import React, { useEffect } from "react";
import Users from "../../../compnents/pages/Users";
import { Typography } from "@mui/material";

const AllUsers = () => {
  useEffect(() => {
    document.title = `AllUsers`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="main" tabIndex={0}>
      <section className="sectionCenter">
        <Typography
          variant="h1"
          fontSize={24}
          color={"gray"}
          fontWeight={"bold"}
        >
          Dashboard
        </Typography>
        <hr />
        <Users />
      </section>
    </main>
  );
};

export default AllUsers;
