import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  LinearProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Snackbar,
  Alert,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../../common/Button";
import { allowedTypes } from "../../../../constants/types";
import { getAllResources } from "../../../../store/api/resources";
import { useQuery } from "@tanstack/react-query";
import ResourceSelectionModal from "./ResourcesModal";
import {
  convertBlobToBase64,
  fetchAndConvertToBase64,
  getMimeType,
} from "../../../../helpers/validator";

const MAX_TOTAL_SIZE = 100 * 1024 * 1024; // 100MB in bytes

const AddResource = ({ onUploadComplete, step, setStep, files, setFiles }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
  const [processingFiles, setProcessingFiles] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const fileInputRef = useRef(null);
  const progressIntervals = useRef({});
  const currentTotalSize = files.reduce(
    (total, file) => total + (file.size || 0),
    0
  );

  const { data: ResourcesList, isLoading } = useQuery({
    queryKey: ["allresourcesList"],
    queryFn: () => getAllResources({ IsOnlyImage: false }),
  });

  useEffect(() => {
    return () => {
      Object.values(progressIntervals.current).forEach(clearInterval);
    };
  }, []);

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadstart = () => {
        // Initialize progress for this file
        setUploadProgress((prev) => ({ ...prev, [file.name]: 0 }));
      };

      reader.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress((prev) => ({ ...prev, [file.name]: progress }));
        }
      };

      reader.onload = () => {
        const base64String = reader.result;
        // Keep the full base64 string including the data URL prefix
        resolve(base64String);
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };
  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") return;
    setToast({ ...toast, open: false });
  };

  const showToast = (message, severity = "error") => {
    setToast({
      open: true,
      message,
      severity,
    });
  };
  const validateFile = (file) => {
    if (!allowedTypes.includes(file.type)) {
      showToast(`File type ${file.type} is not supported`);
      return false;
    }

    if (currentTotalSize + file.size > MAX_TOTAL_SIZE) {
      showToast(
        `Adding this file would exceed the 100MB total limit.
        Current total: ${(currentTotalSize / (1024 * 1024)).toFixed(2)}MB
        File size: ${(file.size / (1024 * 1024)).toFixed(2)}MB
        Limit: 100MB`
      );
      return false;
    }

    return true;
  };

  const handleFiles = async (newFiles) => {
    try {
      const validFiles = Array.from(newFiles).filter(validateFile);

      if (validFiles.length === 0) {
        return;
      }
      // Process all valid files
      const processedFiles = await Promise.all(validFiles.map(processFile));

      // Filter out failed uploads
      const successfulFiles = processedFiles.filter((file) => file !== null);

      // Update files state with new files
      setFiles((prev) => [...prev, ...successfulFiles]);

      // Call onUploadComplete callback if provided
      if (onUploadComplete && successfulFiles.length > 0) {
        onUploadComplete({ files: successfulFiles });
      }
    } catch (error) {
      console.error("Error handling files:", error);
      alert("An error occurred while processing the files");
    }
  };

  const processFile = async (file) => {
    try {
      setProcessingFiles(true);
      const blob = new Blob([file], { type: file.type });
      const base64Data = await convertBlobToBase64(blob);

      const formattedFile = {
        fileName: file.name,
        name: file.name,
        contentType: file.type,
        fileData: base64Data, // Now contains only the base64 data without the prefix
        size: file.size,
        isLocal: true,
        dateAdded: new Date().toISOString(),
      };

      setUploadProgress((prev) => ({ ...prev, [file.name]: 100 }));
      return formattedFile;
    } catch (error) {
      console.error("Error processing file:", error);
      setUploadProgress((prev) => ({ ...prev, [file.name]: 0 }));
      showToast(`Failed to process file ${file.name}`);
      return null;
    } finally {
      setProcessingFiles(false);
    }
  };

  const handleResourceSelect = async (selectedResources) => {
    try {
      const existingTotalSize = files.reduce(
        (total, file) => total + (file.size || 0),
        0
      );

      // Process resources and convert URLs to base64
      const formattedResources = await Promise.all(
        selectedResources.map(async (resource) => {
          try {
            console.log(resource);
            const base64Data = await fetchAndConvertToBase64(
              resource.fileUrl || resource.url,
              true
            );

            return {
              fileName: resource.title,
              name: resource.title,
              contentType:
                getMimeType(resource?.title) ||
                getMimeType(resource?.name) ||
                resource.contentType ||
                resource.mimeType ||
                resource.type,
              fileData: base64Data, // Now contains only the base64 data without the prefix
              id: resource.id,
              size: (resource.fileSize || 0) * 1024 * 1024,
              dateAdded: resource.dateAdded || new Date().toISOString(),
              isResource: true,
            };
          } catch (error) {
            console.error(
              `Error processing resource ${resource.title}:`,
              error
            );
            showToast(`Failed to process resource ${resource.title}`);
            return null;
          }
        })
      );

      const validResources = formattedResources.filter(
        (resource) => resource !== null
      );

      const newFiles = validResources.filter(
        (newFile) =>
          !files.some((existing) => existing.fileName === newFile.fileName)
      );

      const newFilesSize = newFiles.reduce(
        (total, file) => total + (file.size || 0),
        0
      );
      const totalSize = existingTotalSize + newFilesSize;

      if (totalSize > MAX_TOTAL_SIZE) {
        showToast(
          `Total file size would exceed 100MB limit. Please select fewer resources.
          Current total: ${(existingTotalSize / (1024 * 1024)).toFixed(2)}MB
          Selected files total: ${(newFilesSize / (1024 * 1024)).toFixed(2)}MB
          Limit: 100MB`
        );
        return;
      }

      if (newFiles.length > 0) {
        const newProgress = newFiles.reduce(
          (acc, file) => ({
            ...acc,
            [file.fileName]: 100,
          }),
          {}
        );

        setUploadProgress((prev) => ({ ...prev, ...newProgress }));
        setFiles((prev) => [...prev, ...newFiles]);
        showToast("Resources added successfully", "success");

        if (onUploadComplete) {
          onUploadComplete({ files: newFiles });
        }
      }
    } catch (error) {
      console.error("Error handling resource selection:", error);
      showToast("Failed to process selected resources");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const newFiles = Array.from(e.dataTransfer.files);
    handleFiles(newFiles);
  };

  const handleFileInput = (e) => {
    const newFiles = Array.from(e.target.files);
    console.log(newFiles);
    handleFiles(newFiles);
  };

  const removeFile = (fileName) => {
    setFiles(files.filter((file) => file.fileName !== fileName));
    setUploadProgress((prev) => {
      const newProgress = { ...prev };
      delete newProgress[fileName];
      return newProgress;
    });
  };

  return (
    <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
      <Button
        sx={{ mt: 2 }}
        width="40%"
        text="Import Resources"
        onClick={() => setIsResourceModalOpen(true)}
        disabled={processingFiles}
      />

      <Paper
        variant="outlined"
        sx={{
          p: 3,
          mt: 2,
          border: "2px dashed",
          borderColor: isDragging ? "primary.main" : "grey.300",
          bgcolor: isDragging ? "primary.50" : "background.paper",
          textAlign: "center",
          cursor: "pointer",
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current?.click()}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          multiple
          onChange={handleFileInput}
          accept=".pdf,.docx,.doc,.xlsx,.xls,.jpg,.png,.zip,.ppt,.pptx,.mp4,.mov"
          disabled={processingFiles}
        />

        <CloudUploadIcon
          sx={{ fontSize: 48, color: "text.secondary", mb: 2 }}
        />
        <Typography variant="h6" gutterBottom>
          Click to upload or drag and drop
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Allowed file types: pdf, docx, doc, xlsx, xls, jpg, png, zip, ppt,
          pptx, mp4, mov
        </Typography>
      </Paper>

      {files.length > 0 && (
        <List>
          {files.map((file, i) => (
            <ListItem
              key={i}
              sx={{ bgcolor: "grey.50", mb: 1, borderRadius: 1 }}
            >
              <ListItemText
                primary={file.fileName}
                secondary={
                  <Box sx={{ width: "100%" }}>
                    <Typography variant="body2" color="text.secondary">
                      {(file.size / (1024 * 1024)).toFixed(1)} MB
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress[file.fileName] || 0}
                      sx={{ mt: 1 }}
                    />
                  </Box>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={() => removeFile(file.fileName)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}

      <ResourceSelectionModal
        open={isResourceModalOpen}
        onClose={() => setIsResourceModalOpen(false)}
        onResourceSelect={handleResourceSelect}
        resources={ResourcesList?.paginatedContent || []}
        currentTotalSize={currentTotalSize}
      />
      <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
        <Grid item>
          <Button
            text="Go back"
            onClick={() => setStep(step - 1)}
            backgroundColor="white"
            disabled={processingFiles}
          />
        </Grid>
        <Grid item>
          <Button
            text="Next"
            onClick={() => setStep(step + 1)}
            // disabled={processingFiles || files.length === 0}
          />
        </Grid>
        <Snackbar
          open={toast.open}
          autoHideDuration={6000}
          onClose={handleCloseToast}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseToast}
            severity={toast.severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {toast.message}
          </Alert>
        </Snackbar>
      </Grid>
    </Box>
  );
};

export default AddResource;
