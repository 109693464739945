import React from "react";
import { Paper, Typography, Box } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";

const CustomPieChart = ({ data, title }) => {
  const pieColors = ["#10B981", "#4B9EFF", "#8B5CF6"];

  const processedData = React.useMemo(() => {
    if (!data) return [];
    return data
      .sort((a, b) => a.value - b.value)
      .map((article) => ({
        id: article.name,
        value: article.count,
        label: article.name,
      }));
  }, [data]);

  const total = processedData.reduce((sum, entry) => sum + entry.value, 0);

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        height: "100%",
        minHeight: "300px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: "0.875rem",
          fontWeight: 500,
          color: "#374151",
          marginBottom: 2,
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <PieChart
          series={[
            {
              data: processedData,
              innerRadius: 60,
              outerRadius: 100,
              paddingAngle: 2,
              cornerRadius: 4,
              startAngle: -180,
              endAngle: 180,
              colors: pieColors,
              tooltip: {
                valueFormatter: (value) => {
                  const percentage = ((value / total) * 100).toFixed(0);
                  return `${value} (${percentage}%)`;
                },
              },
            },
          ]}
          height={250}
          margin={{
            top: 10,
            bottom: 10,
            left: 20,
            right: 20,
          }}
          slotProps={{
            legend: { hidden: true },
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            mt: 2,
            width: "100%",
            maxWidth: "280px",
          }}
        >
          {processedData.map((item, index) => (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontSize: "0.75rem",
                color: "#4B5563",
              }}
            >
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: "100%",
                  backgroundColor: pieColors[index],
                }}
              />
              <Typography variant="caption">
                {item.label} ({((item.value / total) * 100).toFixed(0)}%)
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default CustomPieChart;
