import React from "react";
import { Paper, Typography } from "@mui/material";
import { useOrganisation } from "../../../hooks/useOrganisation";
import { getTextColor } from "../../../helpers";

const DataCard = ({ title, count, onClick, isSelected }) => {
  const { primaryColorLight, primaryColor } = useOrganisation();

  return (
    <Paper
      elevation={isSelected ? 6 : 3}
      sx={{
        textAlign: "center",
        padding: 2,
        backgroundColor: primaryColorLight,
        cursor: "pointer",
        position: "relative",
        transition: "all 0.2s ease-in-out",
        transform: isSelected ? "scale(1.02)" : "scale(1)",
        border: isSelected ? "2px solid black" : "none",
        "&:hover": {
          transform: "scale(1.02)",
          boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
        },
        "&::after": isSelected
          ? {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: "4px",
              boxShadow: "0 0 0 2px black",
              animation: "pulse 2s infinite",
            }
          : {},
        "@keyframes pulse": {
          "0%": {
            transform: "scale(1)",
            opacity: 0.8,
          },
          "50%": {
            transform: "scale(1.02)",
            opacity: 0.4,
          },
          "100%": {
            transform: "scale(1)",
            opacity: 0.8,
          },
        },
      }}
      onClick={onClick}
    >
      <Typography
        variant="h6"
        sx={{
          color: getTextColor(primaryColor) || "black",
          fontWeight: isSelected ? 600 : 400,
          transition: "color 0.2s ease-in-out",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h4"
        sx={{
          mt: 1,
          fontWeight: isSelected ? 700 : 500,
          color: getTextColor(primaryColor) || "black",
          transition: "color 0.2s ease-in-out",
        }}
      >
        {count}
      </Typography>
    </Paper>
  );
};

export default DataCard;
