import { Box, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Restore } from "@mui/icons-material";

export const columns = ({
  setSortBy,
  setIsDescending,
  isDescending,
  handleDelete,
  setIsEdit,
  handleOpen,
  setIdFeed,
  navigate,
  companyName,
}) => [
  {
    accessorKey: "title",
    header: ({ table }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Label</Typography>
      </div>
    ),
    cell: ({ row, value }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.title}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "status",
    header: ({ table }) => (
      <div>
        <Typography variant="body2">Active</Typography>
      </div>
    ),
    cell: ({ row, value }) => (
      <>
        <Box component="span" sx={{ marginLeft: 1 }}>
          {row.original.status}
        </Box>
      </>
    ),
  },
  {
    accessorKey: "actions",
    header: ({ table }) => (
      <div
        style={{ display: "flex", alignItems: "center" }}
        onClick={() => {
          setSortBy("Author");
          setIsDescending(!isDescending);
        }}
      >
        <Typography variant="body2">Actions</Typography>
      </div>
    ),
    cell: ({ row }) => (
      <>
        <IconButton
          onClick={() => {
            handleOpen();
            setIsEdit(true);
            setIdFeed(row.original.id);
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton
          color="error"
          onClick={() => handleDelete(row.original.status, row.original.id)}
        >
          {row.original.status === "Y" ? (
            <DeleteIcon fontSize="small" />
          ) : (
            <Restore fontSize="small" />
          )}
        </IconButton>
        <IconButton
          onClick={() =>
            navigate(`/${companyName}/all-content?tag=${row.original.title}`)
          }
        >
          <VisibilityIcon fontSize="small" />
        </IconButton>
      </>
    ),
  },
];
