import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import ReactQuill from "react-quill";

import {
  TextField,
  Grid,
  Typography,
  Chip,
  FormControl,
  Box,
  Autocomplete,
  Button as MUIButton,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";

import { getTopicsForArticle } from "../../../../store/api/content";
import CreateImage from "./CreateContent";
import Button from "../../../common/Button";
import { useNavigate } from "react-router-dom";
import { roles } from "../../../../constants/routes";
import { getRole } from "../../../../helpers/auth";

const CreateContent = ({
  setStep,
  step,
  isContentFromUrl,
  setContent,
  content,
  image,
  setImage,
  imageList,
  setTitle,
  title,
  setTopics,
  setTypes,
  setRegions,
  topics,
  types,
  regions,
  imageError,
  titleError,
  summaryError,
  isEdit,
  author,
  setAuthor,
}) => {
  const navigate = useNavigate();
  const role = getRole();
  const companyName = localStorage.getItem("companyName");
  const [topicsDATA, setTopicsDATA] = useState([]);
  const [regionsDATA, setRegionsDATA] = useState([]);
  const [typesDATA, setTypesDATA] = useState([]);
  const [errors, setErrors] = useState({
    title: false,
    topics: false,
    content: false,
    types: false,
    regions: false,
  });

  const { mutate } = useMutation({
    mutationFn: getTopicsForArticle,
    onSuccess: (data) => {
      setTopicsDATA(data.topics);
      setTypesDATA(data.types);
      setRegionsDATA(data.regions);
      setAuthor(data?.author);
    },
    onError: (error) => {},
  });

  useEffect(() => {
    mutate();
  }, [mutate]);

  const handleSetForm = () => {
    let hasError = false;
    const newErrors = {
      title: false,
      topics: false,
      types: false,
      regions: false,
      content: false,
    };

    if (title.trim() === "") {
      newErrors.title = true;
      hasError = true;
    }

    if (content.trim() === "") {
      newErrors.content = true;
      hasError = true;
    }

    if (topics.length === 0) {
      newErrors.topics = true;
      hasError = true;
    }
    if (regions.length === 0) {
      newErrors.regions = true;
      hasError = true;
    }
    if (types.length === 0) {
      newErrors.types = true;
      hasError = true;
    }
    setErrors(newErrors);
    if (!hasError) {
      setStep(step + 1);
    }
  };

  return (
    <section className="">
      <Grid container xs={12} direction="column" spacing={1}>
        {/* Title */}
        <Grid item xs={12}>
          <TextField
            label="Title*"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={errors.title}
          />
          {titleError && (
            <Typography variant="caption" color={"red"}>
              We Can't pull the title from the url, please add it
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Author"
            variant="outlined"
            fullWidth
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            placeholder="Defaults to your name if left blank"
            disabled={roles.ContentCreator === role}
          />
        </Grid>
        {/* Topics */}
        <Grid item xs={8}>
          <Autocomplete
            multiple
            options={["Select All", "Unselect All", ...topicsDATA]}
            getOptionLabel={(option) =>
              option === "Select All" || option === "Unselect All"
                ? ""
                : option.title
            }
            value={topics}
            onChange={(event, values) => {
              if (values.includes("Select All")) {
                setTopics(topicsDATA);
              } else if (values.includes("Unselect All")) {
                setTopics([]);
              } else {
                setTopics(
                  values.filter(
                    (val) => val !== "Select All" && val !== "Unselect All"
                  )
                );
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Topics *"
                error={errors.topics}
              />
            )}
            renderOption={(props, option) =>
              option === "Select All" || option === "Unselect All" ? (
                <Box
                  {...props}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 1,
                    px: 2,
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f0f0f0" },
                  }}
                  onClick={() => {
                    if (option === "Select All") {
                      setTopics(topicsDATA);
                    } else if (option === "Unselect All") {
                      setTopics([]);
                    }
                  }}
                >
                  <MUIButton variant="contained" color="primary" size="small">
                    {option}
                  </MUIButton>
                </Box>
              ) : (
                <li {...props}>{option.title}</li>
              )
            }
          />
        </Grid>
        {/* Types */}
        <Grid item xs={8}>
          <Autocomplete
            multiple
            options={["Select All", "Unselect All", ...typesDATA]}
            getOptionLabel={(option) =>
              option === "Select All" || option === "Unselect All"
                ? ""
                : option.title
            }
            value={types}
            onChange={(event, values) => {
              if (values.includes("Select All")) {
                setTypes(typesDATA);
              } else if (values.includes("Unselect All")) {
                setTypes([]);
              } else {
                setTypes(
                  values.filter(
                    (val) => val !== "Select All" && val !== "Unselect All"
                  )
                );
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Types *"
                error={errors.types}
              />
            )}
            renderOption={(props, option) =>
              option === "Select All" || option === "Unselect All" ? (
                <Box
                  {...props}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 1,
                    px: 2,
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f0f0f0" },
                  }}
                  onClick={() => {
                    if (option === "Select All") {
                      setTypes(typesDATA);
                    } else if (option === "Unselect All") {
                      setTypes([]);
                    }
                  }}
                >
                  <MUIButton variant="contained" color="primary" size="small">
                    {option}
                  </MUIButton>
                </Box>
              ) : (
                <li {...props}>{option.title}</li>
              )
            }
          />
        </Grid>
        {/* Regions */}
        <Grid item xs={8}>
          <Autocomplete
            multiple
            options={["Select All", "Unselect All", ...regionsDATA]}
            getOptionLabel={(option) =>
              option === "Select All" || option === "Unselect All"
                ? ""
                : option.title
            }
            value={regions}
            onChange={(event, values) => {
              if (values.includes("Select All")) {
                setRegions(regionsDATA);
              } else if (values.includes("Unselect All")) {
                setRegions([]);
              } else {
                setRegions(
                  values.filter(
                    (val) => val !== "Select All" && val !== "Unselect All"
                  )
                );
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Regions *"
                error={errors.regions}
              />
            )}
            renderOption={(props, option) =>
              option === "Select All" || option === "Unselect All" ? (
                <Box
                  {...props}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 1,
                    px: 2,
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f0f0f0" },
                  }}
                  onClick={() => {
                    if (option === "Select All") {
                      setRegions(regionsDATA);
                    } else if (option === "Unselect All") {
                      setRegions([]);
                    }
                  }}
                >
                  <MUIButton variant="contained" color="primary" size="small">
                    {option}
                  </MUIButton>
                </Box>
              ) : (
                <li {...props}>{option.title}</li>
              )
            }
          />
        </Grid>
        {/* Image Upload */}
        <Grid item xs={12} md={12}>
          <CreateImage
            image={image}
            setImage={setImage}
            imageList={imageList}
            isContent={true}
          />
          {imageError && (
            <Typography variant="caption" color="error">
              We couldn't extract images from the URL. Please upload an image
              manually.
            </Typography>
          )}
        </Grid>
        {/* Content */}
        <Grid item xs={12} md={12} mt={2}>
          <Typography>Content *</Typography>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              marginBottom: "50px",
              maxWidth: { xs: "300px", sm: "300px", md: "1000px" },
              minWidth: { xs: "300px", sm: "300px", md: "1000px" },
            }}
          >
            <ReactQuill
              theme="snow"
              value={content}
              onChange={setContent}
              style={{
                wordWrap: "break-word",
                width: "100%",
              }}
            />
            {summaryError && (
              <Typography variant="caption" color="error">
                We couldn't extract the summary . Please add content summary
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item container flex={1} justifyContent={"end"}>
          <Grid item>
            <Button
              text="Go back"
              onClick={() =>
                !isEdit
                  ? isContentFromUrl
                    ? setStep(step - 1)
                    : setStep(0)
                  : navigate(`/${companyName}/all-content`)
              }
              backgroundColor="white"
            />
          </Grid>
          <Grid item>
            <Button text="Next" onClick={handleSetForm} />
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};

export default CreateContent;
