export const checkTokenValidation = (token) => {};
export const nameValidator = (value) => {
  if (value.length < 3) return "Name must be at least 3 characters long";
  if (value.length > 20) return "Name must be less than 20 characters long";
  if (!/^[a-zA-Z ]+$/.test(value))
    return "Name must contain only letters and spaces";
  return false;
};
export const emailValidator = (value) => {
  if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(value))
    return true;
  return false;
};

export const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(",")[1]); // Get the base64 part
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const fetchAndConvertToBase64 = async (url, isAzure) => {
  try {
    let response;
    if (isAzure) {
      response = await fetch(
        url +
          `?sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D`
      );
    } else {
      response = await fetch(url);
    }
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(",")[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting file to base64:", error);
    throw error;
  }
};
export const getMimeType = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  const mimeTypes = {
    pdf: "application/pdf",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ppt: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
  };
  return mimeTypes[extension] || "application/octet-stream";
};
// First, add this utility function to convert URL to base64
export const urlToBase64 = async (url) => {
  try {
    const response = await fetch(url, {
      mode: "no-cors",
      cache: "no-cache",
      crossOrigin: "anonymous",
    });
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Get base64 data without the data:image/xxx;base64, prefix
        const base64data = reader.result.split(",")[1];
        resolve(base64data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting URL to base64:", error);
    return null;
  }
};

export const convertImageUrlToBase64 = async (imageUrl) => {
  try {
    // First try with fetch API
    const response = await fetch(imageUrl, {
      mode: "cors",
      headers: {
        Accept: "image/*",
      },
    });

    if (!response.ok) {
      throw new Error("Image fetch failed");
    }

    const blob = await response.blob();
    const fileName = imageUrl.split("/").pop();
    const contentType = response.headers.get("Content-Type") || blob.type;

    // Convert blob to base64
    const fileData = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(",")[1];
        resolve(base64Data);
      };
      reader.readAsDataURL(blob);
    });

    return {
      fileName,
      contentType,
      fileData,
    };
  } catch (error) {
    // If fetch fails, try with Image object
    try {
      // Create a promise that resolves when the image loads or errors
      const imageLoadPromise = new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous"; // Try anonymous first

        const loadImage = () => {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          try {
            // Get base64 data
            const base64Data = canvas
              .toDataURL("image/png")
              .replace(/^data:image\/(png|jpg|jpeg);base64,/, "");

            const fileName = imageUrl.split("/").pop().split("?")[0]; // Remove query params from filename

            resolve({
              fileName,
              contentType: "image/png",
              fileData: base64Data,
              url: imageUrl, // Keep original URL as fallback
            });
          } catch (error) {
            // If canvas tainted, return object with URL only
            resolve({
              url: imageUrl,
            });
          }
        };

        img.onload = loadImage;

        img.onerror = () => {
          // If anonymous fails, try without crossOrigin
          img.crossOrigin = null;
          img.src = imageUrl;

          // If both attempts fail, return object with URL only
          img.onerror = () => {
            resolve({
              url: imageUrl,
            });
          };
        };

        // First attempt with crossOrigin='anonymous'
        img.src = imageUrl;
        // Add timestamp or random query parameter to bypass cache
        if (img.src.indexOf("?") === -1) {
          img.src = `${img.src}?t=${Date.now()}`;
        }
      });
      return await imageLoadPromise;
    } catch (imgError) {
      throw new Error(`Failed to load image: ${imgError.message}`);
    }
  }
};
