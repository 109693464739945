import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import FileViewerModal from "../../Config/Resources/ViewFile";

const ArticleResources = ({ files, primaryColor }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const getResourceType = (file) => {
    // Check file extension for videos
    if (
      file.url?.toLowerCase().endsWith(".mp4") ||
      file.url?.toLowerCase().endsWith(".mov") ||
      file.contentType.startsWith("video/")
    ) {
      return "Video";
    }

    if (file.contentType.startsWith("image/")) return "Image";
    if (file.contentType.startsWith("application/pdf")) return "PDF";
    if (file.contentType.includes("word")) return "Word Doc";
    if (
      file.contentType.includes("excel") ||
      file.contentType.includes("spreadsheet")
    )
      return "Excel";
    if (
      file.contentType.includes("powerpoint") ||
      file.contentType.includes("presentation")
    )
      return "PowerPoint";
    if (file.contentType.startsWith("text/")) return "Text";
    if (file.contentType.startsWith("audio/")) return "Audio";

    // Check extensions for octet-stream
    const ext = file.url?.split(".").pop()?.toLowerCase();
    if (ext === "mp4" || ext === "mov") return "Video";
    if (["jpg", "jpeg", "png", "gif"].includes(ext)) return "Image";
    if (ext === "pdf") return "PDF";

    return "File";
  };

  const handleFileClick = (file) => {
    setSelectedFile(file);
    setOpenDialog(true);
  };

  const getFileButton = (file) => {
    const baseStyles = {
      marginRight: 2,
      marginBottom: 1,
      textTransform: "none",
      borderRadius: "4px",
      padding: "6px 16px",
      display: "flex",
      alignItems: "center",
      gap: 1,
    };

    const resourceType = getResourceType(file);
    const isPreviewable = ["Video", "Image", "PDF"].includes(resourceType);

    return (
      <Button
        variant="contained"
        onClick={() => handleFileClick(file)}
        sx={{
          ...baseStyles,
          backgroundColor: isPreviewable ? primaryColor : "#F3F4F6",
          color: isPreviewable ? "white" : "black",
          "&:hover": {
            backgroundColor: isPreviewable ? primaryColor : "#E5E7EB",
            opacity: 0.9,
          },
        }}
      >
        {`${isPreviewable ? "View" : "Download"} ${resourceType} >`}
      </Button>
    );
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedFile(null);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {files?.map((file, index) => (
          <Box
            key={file.id || index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {getFileButton(file)}
          </Box>
        ))}
      </Box>

      <FileViewerModal
        open={openDialog}
        onClose={handleClose}
        fileData={{
          title: selectedFile?.title,
          fileUrl: selectedFile?.url,
        }}
      />
    </Box>
  );
};

export default ArticleResources;
