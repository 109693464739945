import axios, { AxiosResponse } from "axios";
import { URL } from "../endpoints/index";

// Define types for the login function parameters and response
interface LoginParams {
  email: string;
  password: string;
  organisationId: string;
}

interface LoginResponse {
  msg: string;
  user: any; // You can replace `any` with the actual user type if known
}

/**
 * @method POST
 * @route
 * @body {Object} user
 * @returns {Promise<LoginResponse>} returns a Promise either an error or the response object msg & user
 */
export const login = async ({
  email,
  password,
  organisationId,
}: LoginParams): Promise<LoginResponse> => {
  try {
    console.log(organisationId);
    const response: AxiosResponse<LoginResponse> = await axios.post(
      `${URL.USER_PROFILES.BASE}${URL.USER_PROFILES.V1_USER_LOGIN}/${organisationId}`,
      {
        email,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Define types for the forgetPassword function parameters and response
interface ForgetPasswordParams {
  email: string;
}

// Define types for the forgetPassword function parameters and response
interface ResetPasswordParams {
  email: string;
  token: string;
  newPassword: string;
}

interface ForgetPasswordResponse {
  msg: string; // Adjust based on the actual response structure
}

/**
 * @method POST
 * @route
 * @body {Object} user
 * @returns {Promise<ForgetPasswordResponse>} returns a Promise either an error or the response object msg & user
 */
export const forgetPassword = async ({
  email,
}: ForgetPasswordParams): Promise<ForgetPasswordResponse> => {
  // try {
  const response: AxiosResponse<ForgetPasswordResponse> = await axios.post(
    `${URL.USER_PROFILES.BASE}${URL.USER_PROFILES.V1_USER_FORGET_PASSWORD}?email=${email}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

/**
 * @method POST
 * @route
 * @body {Object} user
 * @returns {Promise<ForgetPasswordResponse>} returns a Promise either an error or the response object msg & user
 */
export const resetPassword = async ({
  email,
  token,
  newPassword,
}: ResetPasswordParams): Promise<ForgetPasswordResponse> => {
  // try {
  const response: AxiosResponse<ForgetPasswordResponse> = await axios.post(
    `${URL.USER_PROFILES.BASE}${URL.USER_PROFILES.V1_USER_RESET_PASSWORD}`,
    { email, token, newPassword },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

// Define types for the forgetPassword function parameters and response
interface refreshTokenParams {
  refreshTokenData: string;
}

// Define types for the forgetPassword function parameters and response
interface refreshTokenParams {
  accessToken: string;
  refreshToken: string;
}

/**
 * @method POST
 * @route
 * @body {Object} refreshToken
 * @returns {Promise<refreshTokenParams>} returns a Promise either an error or the response objectrefreshToken and token
 */
export const refreshToken = async ({
  refreshTokenData,
}: refreshTokenParams): Promise<refreshTokenParams> => {
  const response: AxiosResponse<refreshTokenParams> = await axios.post(
    `${URL.USER_PROFILES.BASE}${URL.USER_PROFILES.V1_USER_REFRESH_TOKEN}`,
    { refreshToken: refreshTokenData },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET users for admin

interface ContentResponse {
  latestApproved: [];
  latestFeatured: [];
  paginatedContent: [];
  continuationToken: null;
}
interface getAdminContentParams {
  searchTerm: string;
  currentPage: string;
  sortBy: string;
  isDescending: boolean;
  organisationId: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<ContentResponse>} returns a Promise either an error or the response object
 */
export const getAllUsersByAdmin = async ({
  searchTerm,
  currentPage,
  sortBy,
  isDescending,
  organisationId,
}: getAdminContentParams): Promise<ContentResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");
  console.log(organisationId);

  const response: AxiosResponse<ContentResponse> = await axios.get(
    `${URL.MANAGE_USERS.BASE}${
      URL.MANAGE_USERS.V1_GET_ALL_USERS
    }?searchTerm=${searchTerm}&pageNumber=${currentPage}&pageSize=10&sortBy=${sortBy}&isDescending=${isDescending}&${
      organisationId && `organisationId=${organisationId}`
    }`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for add user for admin

interface UserResponse {
  latestApproved: [];
  latestFeatured: [];
  paginatedContent: [];
  continuationToken: null;
}
interface AddUserByAdminParams {
  fullName: string;
  email: string;
  locationId: string;
  userTypeId: string;
  organisationId: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<ContentResponse>} returns a Promise either an error or the response object
 */
export const addUserByAdmin = async ({
  fullName,
  email,
  locationId,
  userTypeId,
  organisationId,
}: AddUserByAdminParams): Promise<ContentResponse> => {
  console.log(organisationId);
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<ContentResponse> = await axios.post(
    `${URL.MANAGE_USERS.BASE}${URL.MANAGE_USERS.V1_ADD_USER}?${
      organisationId && `organisationId=${organisationId}`
    }`,
    { fullName, email, locationId, userTypeId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET template to create user for admin

interface UserTypeResponse {
  locations: [];
  userTypes: [];
}

/**
 * @method GET
 * @route
 * @returns {Promise<UserTypeResponse>} returns a Promise either an error or the response object
 */
export const getTemplateCreationUser = async (): Promise<UserTypeResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<UserTypeResponse> = await axios.get(
    `${URL.MANAGE_USERS.BASE}${URL.MANAGE_USERS.V1_GET_CREATION_TEMPLATE_USER}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET template to update user for admin

interface UserUpdateResponse {
  id?: string;
  fullName: string;
  email: string;
  dateCreated: string;
  locations: Location[]; // Array of Location objects
  userTypes: UserType[]; // Array of UserType objects
}
interface Location {
  id: string;
  title: string;
  isSelected: boolean;
}

interface UserType {
  id: string;
  title: string;
  isSelected: boolean;
}

interface GetUserTemplateByAdminParams {
  id?: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<UserUpdateResponse>} returns a Promise either an error or the response object
 */
export const getUserById = async ({
  id,
}: GetUserTemplateByAdminParams): Promise<UserUpdateResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<UserUpdateResponse> = await axios.get(
    `${URL.MANAGE_USERS.BASE}${URL.MANAGE_USERS.V1_GET_USER_BY_ID}/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for GET template to update user for admin

interface UpdateUserParams {
  id?: string;
  fullName: string;
  email: string;
  dateCreated: string;
  locationId: string;
  userTypeId: string;
}

interface userUpdateResponse {
  msg?: string;
}
/**
 * @method GET
 * @route
 * @returns {Promise<userUpdateResponse>} returns a Promise either an error or the response object
 */
export const editUserByAdmin = async ({
  id,
  fullName,
  email,
  dateCreated,
  locationId,
  userTypeId,
}: UpdateUserParams): Promise<userUpdateResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<userUpdateResponse> = await axios.put(
    `${URL.MANAGE_USERS.BASE}${URL.MANAGE_USERS.V1_ADD_USER}/${id}`,
    { fullName, email, dateCreated, locationId, userTypeId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for delete content

interface deleteUserParams {
  userIds: string;
}
interface UserResponse {
  msg: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<UserResponse>} returns a Promise either an error or the response object
 */
export const deleteUsers = async (
  userIds: deleteUserParams
): Promise<UserResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<UserResponse> = await axios.put(
    `${URL.MANAGE_USERS.BASE}${URL.MANAGE_USERS.V1_DELETE_ADMIN_USERS}`,
    userIds,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
// -----------------------------------------------------
// Define types for add user for admin

interface UploadUserResponse {
  msg: string;
}
interface UploadUserByAdminParams {
  users: [];
  userTypeId: string;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<UploadUserResponse>} returns a Promise either an error or the response object
 */
export const uploadUsersByAdmin = async ({
  users,
  userTypeId,
}: UploadUserByAdminParams): Promise<UploadUserResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<UploadUserResponse> = await axios.put(
    `${URL.MANAGE_USERS.BASE}${URL.MANAGE_USERS.V1_ADMIN_UPLOAD_USERS}`,
    { users, userTypeId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for update user prefernces

interface UpdateUserPreferencesResponse {
  msg: string;
}
interface UpdateUserPreferencesParams {
  userTopicIds: string[];
  userTypeIds: string[];
  userRegionIds: string[];
  IsDefaultView: boolean;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<UpdateUserPreferencesResponse>} returns a Promise either an error or the response object
 */
export const updateUserPrefrences = async ({
  userTopicIds,
  userTypeIds,
  userRegionIds,
  IsDefaultView,
}: UpdateUserPreferencesParams): Promise<UpdateUserPreferencesResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<UpdateUserPreferencesResponse> =
    await axios.put(
      `${URL.MANAGE_USERS.BASE}${URL.MANAGE_USERS.V1_UPDATE_USER_PREFERENCE}`,
      { userTopicIds, userTypeIds, userRegionIds, IsDefaultView },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  return response.data;
};

// -----------------------------------------------------
// Define types for get user prefernces
interface Preferences {
  id: string;
  title: string;
  isSelected: boolean;
}
interface getUserPreferencesResponse {
  regions: Preferences[];
  topics: Preferences[];
  types: Preferences[];
  isDefaultView: boolean;
}

/**
 * @method GET
 * @route
 * @returns {Promise<getUserPreferencesResponse>} returns a Promise either an error or the response object
 */
export const getUserPrefrences =
  async (): Promise<getUserPreferencesResponse> => {
    const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

    const response: AxiosResponse<getUserPreferencesResponse> = await axios.get(
      `${URL.MANAGE_USERS.BASE}${URL.MANAGE_USERS.V1_GET_USER_PREFERENCES}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  };

// -----------------------------------------------------
// Define types for update user prefernces

interface UpdateUserNotificationResponse {
  msg: string;
}
interface UpdateUserNotificationParams {
  browserAlert: boolean;
  newsLetter: boolean;
  emailNotification: Number;
}
/**
 * @method PUT
 * @route
 * @returns {Promise<UpdateUserNotificationResponse>} returns a Promise either an error or the response object
 */
export const updateUserNotification = async ({
  browserAlert,
  newsLetter,
  emailNotification,
}: UpdateUserNotificationParams): Promise<UpdateUserNotificationResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<UpdateUserNotificationResponse> =
    await axios.put(
      `${URL.MANAGE_USERS.BASE}${URL.MANAGE_USERS.V1_UPDATE_USER_NOTIFICATION}`,
      { browserAlert, newsLetter, emailNotification },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  return response.data;
};
// -----------------------------------------------------
// Define types for get user prefernces
interface Notification {
  id: string;
  title: string;
  isSelected: boolean;
}
interface getUserNotificationResponse {
  browserAlert: boolean;
  newsLetter: boolean;
  notifications: Notification[];
}

/**
 * @method GET
 * @route
 * @returns {Promise<getUserNotificationResponse>} returns a Promise either an error or the response object
 */
export const getUserNotification =
  async (): Promise<getUserNotificationResponse> => {
    const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

    const response: AxiosResponse<getUserNotificationResponse> =
      await axios.get(
        `${URL.MANAGE_USERS.BASE}${URL.MANAGE_USERS.V1_GET_USER_NOTIFICATION}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    return response.data;
  };

// -----------------------------------------------------
// Define types for update user details

interface UpdateUserDetailsResponse {
  msg: string;
}
interface UpdateUserDetailsParams {
  fullName: string;
  email: string;
  locationId: string;
  regionId: string;
  companyType?: string;
  jobTitle?: string;
  timezoneId: string;
  updatePasswordModel?: {
    currentPassword?: string;
    newPassword?: string;
    confirmNewPassword?: string;
  };
}
/**
 * @method PUT
 * @route
 * @returns {Promise<UpdateUserDetailsResponse>} returns a Promise either an error or the response object
 */
export const updateUserDetails = async ({
  fullName,
  email,
  locationId,
  regionId,
  companyType,
  jobTitle,
  updatePasswordModel,
  timezoneId,
}: UpdateUserDetailsParams): Promise<UpdateUserDetailsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<UpdateUserDetailsResponse> = await axios.put(
    `${URL.MANAGE_USERS.BASE}${URL.MANAGE_USERS.V1_UPDATE_USER_DETAILS}`,
    {
      fullName,
      email,
      locationId,
      regionId,
      companyType,
      jobTitle,
      updatePasswordModel,
      timezoneId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// -----------------------------------------------------
// Define types for get user details
interface Country {
  id: string;
  title: string;
  isSelected: boolean;
}
interface getUserDetailsResponse {
  countries: Country[];
  regions: Country[];
  fullName: string;
  email: string;
  companyType: string;
  jobTitle: string;
  timezones: Country[];
}

/**
 * @method GET
 * @route
 * @returns {Promise<getUserDetailsResponse>} returns a Promise either an error or the response object
 */
export const getUserDetails = async (): Promise<getUserDetailsResponse> => {
  const token = localStorage.getItem("token")?.replace(/^"|"$/g, "");

  const response: AxiosResponse<getUserDetailsResponse> = await axios.get(
    `${URL.MANAGE_USERS.BASE}${URL.MANAGE_USERS.V1_GET_USER_DETAILS}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

// Define types for the login function parameters and response
interface LoginParams {
  email: string;
  password: string;
}

interface LoginResponse {
  msg: string;
  user: any; // You can replace `any` with the actual user type if known
}

/**
 * @method POST
 * @route
 * @body {Object} user
 * @returns {Promise<LoginResponse>} returns a Promise either an error or the response object msg & user
 */
export const superAdminLogin = async ({
  email,
  password,
}: LoginParams): Promise<LoginResponse> => {
  try {
    const response: AxiosResponse<LoginResponse> = await axios.post(
      `${URL.USER_PROFILES.BASE}${URL.USER_PROFILES.V1_SUPERADMIN_LOGIN}`,
      {
        email,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
