import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Box, Typography, Grid, Divider } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getArticleByid } from "../../../store/api/content";
import Loading from "../../common/Loading";
import Button from "../../common/Button";
import { format } from "date-fns";

const PreviewContent = ({ open, handleClose, id }) => {
  const navigate = useNavigate();
  const companyName = localStorage.getItem("companyName");

  const { data, isLoading } = useQuery({
    queryKey: ["article", id],
    queryFn: () =>
      getArticleByid({
        id,
      }),
    enabled: !!id,
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%", // Adjust width for smaller screens
          maxWidth: 900,
          maxHeight: "90vh", // Max height to ensure content is visible
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 8,
          borderRadius: 2,
          overflowY: "auto", // Enables vertical scrolling within the modal
        }}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" component="h2" gutterBottom>
                {data?.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {data?.author || ""}{" "}
                {data?.scheduledDate
                  ? format(new Date(data.scheduledDate), "dd MMM yyyy")
                  : "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img
                src={`${
                  data?.mainImage
                }?${"sp=r&st=2023-09-03T09:14:50Z&se=2025-09-03T17:14:50Z&spr=https&sv=2022-11-02&sr=c&sig=iZvMW0xmkQvxy27%2F6c3tkT30a%2F966cNNeKf%2BHSLiS54%3D"}`}
                alt="Preview"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="body1" paragraph>
                <div dangerouslySetInnerHTML={{ __html: data?.text }} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                {data?.topic?.split(",").map((el) => (
                  <Button text={el} key={el} size="small" />
                ))}
              </Box>
            </Grid>

            {/* Add your buttons and other elements here */}
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
                <Button
                  text="Close"
                  onClick={handleClose}
                  backgroundColor="white"
                  style={{ border: "1px solid black" }}
                />
                <Button
                  text=" Edit content"
                  onClick={() => navigate(`/${companyName}/edit-content/${id}`)}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};
export default PreviewContent;
