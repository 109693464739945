import React, { useEffect } from "react";
import StatiscticsPage from "../../../compnents/pages/Statisctics";
import { Typography } from "@mui/material";

const Statistics = () => {
  useEffect(() => {
    document.title = `Statistics`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="main" tabIndex={0}>
      <section className="sectionCenter">
        <Typography
          variant="h1"
          fontSize={24}
          color={"gray"}
          fontWeight={"bold"}
        >
          Dashboard
        </Typography>
        <hr />
        <StatiscticsPage />
      </section>
    </main>
  );
};

export default Statistics;
