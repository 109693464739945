import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import ArticleIcon from "@mui/icons-material/Article";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import { roles } from "../../../constants/routes";
import { getRole } from "../../../helpers/auth";
import { useOrganisation } from "../../../hooks/useOrganisation";
import { getTextColor } from "../../../helpers";
import "./style.css";

const AdminDashboardPage = () => {
  const navigate = useNavigate();
  const companyName = localStorage.getItem("companyName");
  const role = getRole();

  const { primaryColor } = useOrganisation();
  const textColor = getTextColor(primaryColor) || "black";

  useEffect(() => {
    document.title = `AdminDashboard`;
    window.scrollTo(0, 0);
  }, []);

  const list =
    roles.ContentCreator === role
      ? []
      : [
          {
            text: "Aggregate",
            icon: (
              <CheckCircleIcon
                style={{ alignSelf: "self-end", fill: "gray" }}
              />
            ),
            url: `/${companyName}/Rss-Feed`,
          },
          {
            text: "Approve content",
            icon: (
              <CheckCircleIcon
                style={{ alignSelf: "self-end", fill: "gray" }}
              />
            ),
            url: `/${companyName}/all-content/approve`,
          },
          {
            text: "Queued content",
            icon: (
              <AutoAwesomeMotionIcon
                style={{ alignSelf: "self-end", fill: "gray" }}
              />
            ),
            url: `/${companyName}/all-content/schedule`,
          },
          {
            text: "Newsletter content",
            icon: (
              <ArticleIcon style={{ alignSelf: "self-end", fill: "gray" }} />
            ),
            url: `/${companyName}/all-newsletter`,
          },
          {
            text: "Broadcast to all",
            icon: (
              <ReportProblemIcon
                style={{ alignSelf: "self-end", fill: "gray" }}
              />
            ),
            url: `/${companyName}/broadcast`,
          },
        ];

  const lastList =
    role === roles.SuperAdmin
      ? [
          {
            text: "Add/manage organisations",
            icon: <ManageAccountsIcon style={{ fill: "gray" }} />,
            url: `/${companyName}/all-organisation`,
          },
          {
            text: "Add/manage admin users",
            icon: <CheckCircleIcon style={{ fill: "gray" }} />,
            url: `/${companyName}/all-users`,
          },
          {
            text: "Statistics",
            icon: <SignalCellularAltIcon style={{ fill: "gray" }} />,
            url: `/${companyName}/statistics`,
          },
        ]
      : role === roles.Admin
      ? [
          {
            text: "Update Onboarding data",
            icon: <CheckCircleIcon style={{ fill: "gray" }} />,
            url: `/${companyName}/updateOnboarding`,
          },
          {
            text: "Add/manage admin users",
            icon: <CheckCircleIcon style={{ fill: "gray" }} />,
            url: `/${companyName}/all-users`,
          },
          {
            text: "Statistics",
            icon: <SignalCellularAltIcon style={{ fill: "gray" }} />,
            url: `/${companyName}/statistics`,
          },
          {
            text: "Settings config",
            icon: <PermDataSettingIcon style={{ fill: "gray" }} />,
            url: `/${companyName}/settings/config`,
          },
        ]
      : role === roles.Editor
      ? [
          // {
          //   text: "Settings config",
          //   icon: <PermDataSettingIcon style={{ fill: "gray" }} />,
          //   url: `/${companyName}/settings/config`,
          // },
        ]
      : [];

  return (
    <section style={{ marginTop: 16 }}>
      {role !== roles.SuperAdmin && (
        <Grid container spacing={3} sx={{ padding: 3 }}>
          <Grid
            container
            item
            xs={12}
            spacing={3}
            justifyContent={"space-between"}
          >
            <Grid
              className="adminSettingCard mainCard"
              item
              xs={12}
              md={5.5}
              sm={12}
              sx={{
                "&:hover": {
                  backgroundColor: primaryColor,
                  color: textColor,

                  cursor: "pointer",
                },
              }}
              onClick={() => navigate(`/${companyName}/create-content`)}
            >
              <Typography variant="h6">Create content</Typography>
              <AddCircleOutlineIcon
                style={{ alignSelf: "self-end", fill: "gray" }}
              />
            </Grid>

            <Grid
              className="adminSettingCard mainCard"
              item
              xs={12}
              md={5.5}
              sm={12}
              onClick={() => navigate(`/${companyName}/all-content`)}
              sx={{
                "&:hover": {
                  backgroundColor: primaryColor,
                  color: textColor,
                  cursor: "pointer",
                },
              }}
            >
              <Typography variant="h6">View All Content</Typography>
              <RateReviewIcon style={{ alignSelf: "self-end", fill: "gray" }} />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={3}
            justifyContent={"space-between"}
          >
            {list.map((el, i) => (
              <Grid
                className="adminSettingCard mainCard"
                item
                key={i}
                xs={12}
                sm={12}
                md={2}
                mt={2}
                mr={"4px"}
                style={{
                  maxWidth: "max-content",
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: primaryColor,
                    color: textColor,

                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate(el.url)}
              >
                <Typography
                  variant="h6"
                  style={{ marginRight: 32, padding: 0 }}
                >
                  {el.text}
                </Typography>

                {el.icon}
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}

      <Grid container item xs={12} spacing={3} justifyContent={"space-around"}>
        {lastList.map((el, i) => (
          <Grid
            key={i}
            item
            xs={12}
            sm={12}
            md={3}
            mt={2}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              flexWrap: "wrap",
              border: `1px solid ${primaryColor}`,
              borderRadius: "8px",
              background: "white",
              cursor: "pointer",
              maxWidth: "280px",
              marginRight: "4px",
            }}
            sx={{
              "&:hover": {
                backgroundColor: primaryColor,
                cursor: "pointer",
              },
            }}
            onClick={() => navigate(el.url)}
          >
            <Typography variant="body2">{el.text}</Typography>

            {el.icon}
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

export default AdminDashboardPage;
